const Loading = {
    /**
     * 要素にローディング追加
     * @param {要素}} target 
     */
    addToElement(targetId){
        const button = document.getElementById(targetId);
        //ローディング追加
        button.classList.add('overlay_cover_on_executing_wrap');
        button.disabled = true
        button.insertAdjacentHTML('beforeend', 
            '<div id="loading" class="overlay_cover_on_executing"><img disabled src="/common/image/icon/loading.gif"></div>'
        );
        button.style["pointer-events"] = "none";
    },

    /**
     * 要素にローディング追加(class追加なし)
     * @param {要素}} target
     */
    addToElementNonWrap(targetId){
        const button = document.getElementById(targetId);
        //ローディング追加
        button.disabled = true
        button.insertAdjacentHTML('beforeend',
            '<div id="loading" class="overlay_cover_on_executing"><img disabled src="/common/image/icon/loading.gif"></div>'
        );
        button.style["pointer-events"] = "none";
    },

    /**
     * 要素からローディング削除
     * @param 要素 target 
     */
    removeFromElement(targetId){
        const button = document.getElementById(targetId);
        const loading = document.getElementById('loading');
        if (loading == null) {
            return;
        }
        // ローディング削除
        loading.remove()
        if (button == null) {
            return;
        }
        //ボタン無効化解除
        button.disabled = false
        button.style["pointer-events"] = "";
    },
}
export default Loading;