import React, { useState, useEffect, useRef } from 'react';
import { usePdf } from '@mikecousins/react-pdf';
import pdfjs from 'pdfjs-dist';
import Loading from './FileViewerLoading';

function useReactPdf(file, page) {
    const canvasRef = useRef(null);

    const { pdfDocument, pdfPage } = usePdf({
        canvasRef,
        file: file,
        page: page,
        cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
        cMapPacked: true,
    });

    return [pdfDocument, canvasRef, pdfPage];
}

const FileViewerPdf = ({ view_file_src: file }) => {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(null);
    const [nowpages, setNowPages] = useState(null);

    /**
     * ページング処理.
     *
     */
    const renderPagination = (page, pages) => {
        if (!pages) {
            if (pages !== nowpages) {
                setNowPages(pages);
                setPage(1);
            }
            return null;
        }

        return (
            <div className="swiper-paging">
                {page !== 1 && (
                    <ArrowButton type="prev" onClick={() => setPage(page - 1)} />
                )}
                <span className="paging_number">{page} / {pages}</span>
                {page !== pages && (
                    <ArrowButton type="next" onClick={() => setPage(page + 1)} />
                )}
            </div>
        );
    };

    const [pdfDocument, canvasRef, pdfPage] = useReactPdf(file, page);

    useEffect(() => {
        if (pdfDocument) {
            setPages(pdfDocument.numPages);
        }
        setLoading(!pdfDocument);
    }, [pdfDocument]);

    useEffect(() => {
        setPage(1);
        setLoading(true);
    }, [file]);

    return loading
        ? <Loading />
        : (
            <div>
                <canvas id="canvasprev" ref={canvasRef} className="swiper_image_size" />
                {renderPagination(page, pages)}
            </div>
        );
};

function ArrowButton({ type, onClick }) {
    const handleClick = e => {
        e.preventDefault();
        onClick();
    };

    const arrowClassName = type === 'next' ? 'right_arrow' : 'left_arrow';

    return (
        // cssに影響するので次の行でESLint無効
        // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/anchor-has-content
        <a href="" className={arrowClassName} onClick={handleClick}>
            <span className={type} />
        </a>
    );
}

export default FileViewerPdf;
