class HTTP_METHOD {

    Code = {
        GET: "GET",
        POST: "POST",
        PUT: "PUT",
        DELETE: "DELETE"
    };

}

export default new HTTP_METHOD();

