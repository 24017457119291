import React from 'react';
import {withRouter} from '../../libs/withRouter';
import {ApiEnum, ApiClient, HttpMethod} from '../../api/Api';
import MessageUtil from '../../libs/MessageUtil';

/********************************************************************
 *
 * STORAGE-016 フロントお気に入り一覧
 *
 *******************************************************************/
class FrontBookmarkForm extends React.Component {

    /**
     * 初期化処理を行います.
     *
     * @param {*} props
     */
    constructor (props) {
        super(props);

        // クエリパラメータから値を取得
        let param = new URLSearchParams(window.location.search);
        let tmpUrlParam = '?developerId=' + param.get('developerId') + '&mansionId=' + param.get('mansionId');

        this.state = {
            bookmark_list: [],        // お気に入り一覧
            selected_row_list: [],    // 選択した行一覧
            urlParam: tmpUrlParam,    // ファイルビューア用クエリ
            developer_kind: 'basic',
        }
        this.deleteBookmark = this.deleteBookmark.bind(this);
    }

    /**
     * コンポーネントがマウントされた場合に実行される処理です.
     *
     */
    componentDidMount () {
        console.log('componentDidMount/start');
        // お気に入り一覧を取得
        this.getBookmarkList();
        console.log('componentDidMount/end');
    }

    _didFinish() {
        console.log('_didFinish/start');
        this.getBookmarkList();
        console.log('_didFinish/end');
    }

    /**
     * 指定したユーザのお気に入り一覧を取得します.
     *
     */
    getBookmarkList () {
        console.log('searchBookmarkList/start');

        ApiClient.storageApi(
            ApiEnum.Code.BOOKMARK_GET + encodeURI(this.state.urlParam), 
            {},
            HttpMethod.Code.GET)
            .then(json => {
                // デベロッパーIDによってスタイルを切り替える
                const userInfo = JSON.parse(sessionStorage.getItem('user_info_key'));
                if (userInfo['developerId'] === process.env.REACT_APP_DEVELOPER_ID_NOMURA) {
                    this.setState({developer_kind: 'nomura'});
                } else {
                    this.setState({developer_kind: 'basic'});
                }

                this.setState({
                    bookmark_list: json['bookmark_list'],
                });
            }).catch(err => {
                this.props.router.navigate('/app/error', {
                    state: {code: err.errorCode, message: err.errorMessage}
                });
            }
        )
        console.log('searchBookmarkList/end');
    }


    /**
     * 選択済みの行をstateに保持します.
     *
     */
    checkSelectedRow () {
        console.log('checkSelectedRow/start');
        let tmpSelectedList = [];
        this.state.bookmark_list.forEach(function(rec, index) {
            if (document.getElementById('row' + index).checked) {
                tmpSelectedList.push(rec);
            }
        })
        this.setState({selected_row_list: tmpSelectedList});
        console.log('checkSelectedRow/end');
    }


    /**
     * 選択したファイル・フォルダをお気に入りから削除します.
     *
     */
    deleteBookmark (record) {
        console.log('deleteBookmark/start');

        const self = this;

        if (!window.confirm(MessageUtil.getMessage('I09002'))) {
            self.getBookmarkList();
            return;
        }

        ApiClient.storageApi(
            ApiEnum.Code.BOOKMARK_DELETE,
            {"bookmark_id": record['bookmark_id']},
            HttpMethod.Code.DELETE)
            .then(json => {
                // 削除後のお気に入り一覧を取得
                self.getBookmarkList();
                // 正常ダイアログを表示
                window.alert(MessageUtil.getMessage('I00000'));

            }).catch(err => {
                switch (err.errorCode) {
                    case 'E00007':
                        window.alert(err.errorMessage);
                        break;
                    default:
                        self.props.history.push('/app/error', {
                            state: {code: err.errorCode, message: err.errorMessage}
                        });
                        break;
                }
            }).finally(() => {
            });

        console.log('deleteBookmark/end');
    }

    /**
     * 画面描画処理.
     *
     */
    render () {
        return(
        <div className={this.state.developer_kind}>
            <section className="search_area">
                <h2>お気に入り</h2>
            </section>
            <section className="filelist_area">
              <ul className="file_folder_list">
                {this.state.bookmark_list.map((record, index) =>
                    <FrontBookmarkRow
                        key={index}
                        record={record}
                        index={index}
                        onBookmark={this.deleteBookmark}
                        urlParam={this.state.urlParam}
                    />
                )}
              </ul>
            </section>
        </div>
        )
    }
}

/**
 * お気に入り要素
 * @param {プロパティ} props
 */
const FrontBookmarkRow = props => {
    const rec = props.record;
    const isFolderDesc = rec['folder_explanatory_text'] != null;

    return rec['file_file_id'] != null ? (
            <li className="normal_list_img developer_file_icon">
                <a
                    className="file_item_a"
                    href={
                        '/app/fileviewer?fileId=' + rec['file_file_id'] +
                        '&is_front=true' +
                        '&is_paging=true' +
                        '&is_bookmark=true' +
                        '&caller_path=/app/frontbookmark' + props.urlParam
                    }
                >
                    <h3>{rec['file_file_name']}</h3>
                </a>
                <span className="inner_wrap favorite_wrap">
                    <input type="checkbox" name="favorite_mark" id="mark1" className="favorite_mark" disabled="disabled"/>
                    <label htmlFor="mark1" className="check_on" onClick={() => props.onBookmark(rec)}></label>
                </span>
            </li>
        ) : (
            <li className="normal_folder_img developer_folderlist">
                <a href={'/app/frontweb?folderId=' + rec['folder_folder_id']} className="file_item_a">
                    <h3>{rec['folder_folder_name']}</h3>
                    {isFolderDesc &&
                    <p className="list_description">{rec['folder_explanatory_text']}</p> }
                </a>
                <span className="inner_wrap favorite_wrap">
                    <input type="checkbox" name="favorite_mark" id="mark1" className="favorite_mark" disabled="disabled" />
                    <label htmlFor="mark1" className="check_on" onClick={() => props.onBookmark(rec)}></label>
                </span>
            </li>
        )
}

export default withRouter(FrontBookmarkForm);
