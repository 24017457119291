import React, {Component} from 'react';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

/** 共通系 */
import './common/css/common/common.css';
import './common/css/basic/common.css';
import './common/css/basic/swiper.css';

/** 各業務画面 */
import FileManagementForm from "./forms/file_management/FileManagementForm.jsx";
import FileSearchResultForm from "./forms/file_search_result/FileSearchResultForm.jsx";
import FileViewerForm from "./forms/file_viewer/FileViewerForm.jsx";
import BookmarkForm from "./forms/bookmark/BookmarkForm.jsx";
import FrontWebForm    from "./forms/front_web/FrontWebForm.jsx";
import FrontBookmarkForm from './forms/front_favorite/FrontBookmarkForm';
import FrontWebSearchResultForm from './forms/front_web_search_result/FrontWebSearchResultForm';
import ErrorForm    from "./forms/error/ErrorForm.jsx";


/********************************************************************
 *
 * ルート
 *
 *******************************************************************/
class App extends Component {
    render() {
        return (
            <div>

                {/* コンテンツ表示エリア */}
                <Router>
                    <Routes>
                        <Route path='/app/filesearchresult' element={<FileSearchResultForm />} />
                        <Route path='/app/fileviewer' element={<FileViewerForm />} />
                        <Route path='/app/bookmark' element={<BookmarkForm />} />
                        <Route path='/app/error' element={<ErrorForm />} />
                        <Route path='/app/filemanagement' element={<FileManagementForm />} />
                        <Route path='/app/frontbookmark' element={<FrontBookmarkForm />} />
                        <Route path='/app/frontweb' element={<FrontWebForm />} />
                        <Route path='/app/frontfilesearchresult' element={<FrontWebSearchResultForm />} />
                        <Route render={props => <ErrorForm code={'E00004'} message={process.env.REACT_APP_E00004} {...props}/> } />
                    </Routes>
                </Router>

            </div>
        );
    }
}

export default App;
