import React from 'react';
import {withRouter} from '../../libs/withRouter';
import {ApiEnum, ApiClient, HttpMethod} from '../../api/Api';
import MessageUtil from '../../libs/MessageUtil';
import FileViewerPdf from './FileViewerPdf';
import FileViewerImage from './FileViewerImage';
import FileViewerAudio from './FileViewerAudio';
import FileViewerVideo from './FileViewerVideo';
import FileViewerText from './FileViewerText';
import Loading from '../../libs/Loading';
import MessageDialogForm from '../dialog/MessageDialogForm';
import { useSwipeable  } from 'react-swipeable';

/********************************************************************
 *
 * STORAGE-010 ファイルビューア
 *
 *******************************************************************/
class FileViewerForm extends React.Component {

    /**
     *初期化処理を行います.
     *
     * @param {*} props
     */
    constructor(props) {
        super(props);

        document.title = 'ファイルビューア｜ストレージ';

        // クエリパラメータから値を取得
        let urlParams = new URLSearchParams(window.location.search);
        let urlParamFileId = urlParams.get('fileId');
        let urlParamFolderId = urlParams.get('folder_id');
        let isFront = urlParams.get('is_front');
        let isBookmark = urlParams.get('is_bookmark');
        let isPaging = urlParams.get('is_paging');
        let urlParamCallerPath = urlParams.get('caller_path');
        let urlParamService = urlParams.get('service');
        let externalDomain = '';

        // 外部サービスのドメインを取得
        if (urlParamService) {
            const services = process.env.REACT_APP_SERVICE_DOMAINS.split(", ");
            let domains = {};
            let service = [];
            for (let i = 0; i < services.length; i++) {
                service = services[i].split("=>");
                domains[service.shift()] = service.shift();
            }
            // ドメイン設定
            if (domains[urlParamService]) externalDomain = domains[urlParamService];
            else isFront = '';
        }

        // URLパラメータを隠す
        window.history.pushState(null, null, 'fileviewer?fileId=' + urlParamFileId);

        // iOSかどうかの判定
        const isIos = (/iPad|iPhone|iPod/.test(navigator.userAgent)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

        this.state = {
            message: '',                              // システムメッセージ
            is_front: isFront,                        // フロント画面から呼び出されたか
            is_bookmark: isBookmark,                  // ブックマーク画面から呼び出されたか
            is_paging: isPaging,                      // ページングを行うか
            is_service: externalDomain ? 'true' : '', // 外部サービスからの遷移か
            domain: externalDomain,                   // 外部サービスドメイン
            caller_path: urlParamCallerPath,          // 呼出元パス
            view_folder_id: urlParamFolderId,         // 現在表示しているフォルダのID
            view_file_id: urlParamFileId,             // 現在表示しているファイルのID
            view_file_name: '',                       // 現在表示しているファイルの名前
            view_file_src: '',                        // 現在表示しているファイルのURL
            display_type: '',                         // 現在表示しているファイルの種別
            prev_file_id: '',                         // 一つ前の表示順を持つファイルのID
            next_file_id: '',                         // 一つ後の表示順を持つファイルのID
            download_error_code: '',                  // ダウンロードエラー時のコード
            download_error_message: '',               // ダウンロードエラー時のメッセージ
            is_ios: isIos,                            // iOSかどうか
        }

        // ファイルIDをもとにファイルの拡張子を取得
        this.checkFileExtension(urlParamFileId);
    }

    /**
     * コンポーネントがマウントされた場合に実行される処理です.
     *
     */
    componentDidMount () {
        // ページングを初期化
        this.paging(this.state.view_file_id);

    }

    componentDidUpdate () {
        document.title = this.state.view_file_name + '｜ファイルビューア｜ストレージ';
    }

    /**
     * ファイルID一覧から前ページ、次ページを判定します.
     *
     * @param {基点とするファイルのID} argViewFileId
     */
    paging (argViewFileId) {
        this.setState({view_file_id: argViewFileId});

        // ページ遷移しない場合はページ遷移を無効
        if (this.state.is_paging !== 'true') {
            this.setState({
                prev_file_id: '',
                next_file_id: '',
            });
            return;
        }

        if (this.state.is_bookmark === 'true') {
            // ブックマーク画面から呼び出された場合
            ApiClient.storageApi(
                ApiEnum.Code.FILE_VIEWER_BOOKMARK_PAGING,
                {},
                HttpMethod.Code.GET,
                argViewFileId
            )
            .then(json => {
                this.setState({prev_file_id: json['prev_file_id']});
                this.setState({next_file_id: json['next_file_id']});
            }).catch(err => {
                this.props.router.navigate('/app/error', {
                    state: {code: err.errorCode, message: err.errorMessage}
                });
            });
        } else {
            // ファイル管理画面またはフロントWEB画面から呼び出された場合
            ApiClient.storageApi(
                ApiEnum.Code.FILE_VIEWER_FILELIST_PAGING,
                {},
                HttpMethod.Code.GET,
                this.state.view_folder_id,
                argViewFileId
            )
            .then(json => {
                this.setState({prev_file_id: json['prev_file_id']});
                this.setState({next_file_id: json['next_file_id']});
            }).catch(err => {
                this.props.router.navigate('/app/error', {
                    state: {code: err.errorCode, message: err.errorMessage}
                });
            });
        }

    }

    /**
     * 指定したファイルの拡張子をチェックします.
     *
     * @param {拡張子チェック対象のファイルID} argFileId
     */
    checkFileExtension (argFileId) {
        console.log('checkFileExtension/start');

        ApiClient.storageApi(
            ApiEnum.Code.FILE_EXTENSION_CHECK,
            {},
            HttpMethod.Code.GET,
            argFileId)
            .then(json => {
                // GA送信
                const userInfo = JSON.parse(sessionStorage.getItem('user_info_key'));
                let accountTypeCode = '未認証';
                let mansionId = '未認証';
                let mansionName = '未認証'
                if (userInfo['accountTypeCode'] == 'member') {
                    accountTypeCode = 'フロント';
                    mansionId = userInfo['mansionId'];
                    mansionName = userInfo['mansionName'];
                }
                else if (userInfo['accountTypeCode'] == 'manager') {
                    accountTypeCode = '管理画面';
                    mansionId = '管理画面';
                    mansionName = '管理画面';
                }
                window.gtag('event', 'view_item', {
                    items: [{
                        item_id: argFileId,                                                              // ファイルID
                        item_name: json['file_name'],                                                    // ファイル名
                        promotion_id: userInfo['developerId'] ? userInfo['developerId'] : '未認証',      // デベロッパーID
                        promotion_name: userInfo['developerName'] ? userInfo['developerName'] : '未認証',// デベロッパー名
                        item_category: accountTypeCode,                                                  // アカウント区分
                        item_list_id: mansionId,                                                         // マンションID
                        item_list_name: mansionName                                                      // マンション名
                    }]
                });

                if (json['display_type'] === 98) {
                    this.setState({
                        message: MessageUtil.getMessage('E02031'),
                        view_file_name: json['file_name'],
                        display_type: json['display_type'],
                    });
                    return;
                } else if (json['display_type'] === 99) {
                    this.setState({
                        message: MessageUtil.getMessage('E02010'),
                        view_file_name: json['file_name'],
                        display_type: json['display_type'],
                    });
                    return;
                }

                this.setState({
                    view_file_id: argFileId,
                    view_file_name: json['file_name'],
                    view_file_src: ApiEnum.Code.FILE_VIEW + '?file_id=' + argFileId,
                    display_type: json['display_type'],
                });
            }).catch(err => {
                this.props.router.navigate('/app/error', {
                    state: {code: err.errorCode, message: err.errorMessage}
                });
            }
        )

        console.log('checkFileExtension/end');
    }

    /**
     * 一つ前の表示順のファイルを表示します.
     *
     */
    prevFile () {

        if (this.state.prev_file_id === '') return;

        this.props.router.navigate('/app/fileviewer?fileId=' + this.state.prev_file_id);
        this.paging(this.state.prev_file_id);
        this.checkFileExtension(this.state.prev_file_id);

        // URLパラメータを隠す
        window.history.pushState(null, null, 'fileviewer?fileId=' + this.state.prev_file_id);
    }

    /**
     * 一つ後の表示順のファイルを表示します.
     *
     */
    nextFile () {

        if (this.state.next_file_id === '') return;

        this.props.router.navigate('/app/fileviewer?fileId=' + this.state.next_file_id + '&file_list=');
        this.paging(this.state.next_file_id);
        this.checkFileExtension(this.state.next_file_id);

        // URLパラメータを隠す
        window.history.pushState(null, null, 'fileviewer?fileId=' + this.state.next_file_id);
    }

    swipePrevFile() {
        // iOSの場合、スワイプ不可
        if (this.state.is_ios) {
            return;
        }
        this.prevFile();
    }

    swipeNextFile() {
        // iOSの場合、スワイプ不可
        if (this.state.is_ios) {
            return;
        }
        this.nextFile();
    }

    /**
     * 文章ファイル取得エラーハンドラー
     *
     * @param response
     */
    handleTextFetchError(response) {
        try {
            const json = JSON.parse(response);
            const errorCode = json['errorCode'];
            const errorMessage = json['errorMessage'];
            this.props.router.navigate('/app/error', {
                state: { code: errorCode, message: errorMessage }
            });
        } catch (e) {
            // 予期せぬ例外
            this.props.router.navigate('/app/error');
        }
    }

    /**
     * 指定したファイルをダウンロードします.
     */
    executeDownload () {

        console.log('executeDownload/start');

        Loading.addToElementNonWrap('execute_download');

        let tmpDownloadTarget = [];
        tmpDownloadTarget.push({
            id: this.state.view_file_id,
            file_flg: true,
        });

        let tmpUrlParam = '?';
        tmpDownloadTarget.forEach(function(rec, index) {
            tmpUrlParam += 'targets[' + index + '].id=' + rec.id + '&targets[' + index + '].file_flg=' + rec.file_flg;
        });

        ApiClient.storageApiBlob(
            ApiEnum.Code.FILE_DOWNLOAD + encodeURI(tmpUrlParam),
            {},
            HttpMethod.Code.GET)
            .then(retVal => {
                let blob = retVal[0];
                let headers = retVal[1];

                // レスポンスヘッダーからContent-Dispositionの値を取得
                var contentDisposition = headers.get('content-disposition');
                // ファイル名を抽出
                var searchStr = "attachment; filename*=utf-8''";
                var index = contentDisposition.indexOf(searchStr);
                let fileName = decodeURI(contentDisposition.slice(index + searchStr.length));

                // BLOBファイルをダウンロード
                let link = document.createElement('a');
                if (window.navigator.msSaveBlob) {
                    // for IE
                    window.navigator.msSaveBlob(blob, fileName);
                } else {
                    // for Chrome
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                }

            }).catch(err => {
                // エラー時はエラーメッセージをダイアログ表示する
                this.setState({download_error_code: err.errorCode, download_error_message: err.errorMessage});
                document.getElementById('messagedialog').classList.add('is-show');
            }).finally(() => {
                Loading.removeFromElement('execute_download');
            });
    }

    /**
     * <（戻る）ボタンの遷移先を設定します .
     */
    movePrevPage() {
        // お知らせからの遷移である場合
        if (this.state.is_service === 'true') {
            let link = document.createElement('a');
            link.href = this.state.domain + this.state.caller_path;
            link.click();
        } else {
            document.location = this.state.caller_path;
        }
    }

    // /**
    //  * base64のデータをBLOBへ変換します.
    //  * TODO: たぶんこのメソッド使ってない
    //  *
    //  * @param {base64データ} argBase64 
    //  * @param {base64データのコンテンツタイプ} argContentsType 
    //  */
    // toBlob(argBase64, argContentsType) {
    //     let bin = atob(argBase64.replace(/^.*,/, ''));
    //     let buffer = new Uint8Array(bin.length);

    //     for (let i = 0; i < bin.length; i++) {
    //         buffer[i] = bin.charCodeAt(i);
    //     }

    //     let blob;
    //     try {
    //         blob = new Blob([buffer.buffer], {
    //             type: argContentsType
    //         });
    //     } catch (e) {
    //         return false;
    //     }

    //     return blob;
    // }

    /**
     * 画面描画処理.
     *
     */
    render () {
        const userInfo = JSON.parse(sessionStorage.getItem('user_info_key'));
        return (
            <div>
                <section className="strageview_header">
                    {this.state.is_front === 'true' && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a className="left_justified" onClick={(e) => this.movePrevPage()}>
                            <img src="/common/image/icon/icon_left_arrow.png" alt="file" className="left_arrow_style" />
                        </a>
                    )}
                    <div className="title_area">
                        <p className="title_text">{this.state.view_file_name}</p>
                        {userInfo && userInfo['developerId'] && userInfo['developerId'] !== process.env.REACT_APP_DEVELOPER_ID_NOMURA && (
                            <button id="execute_download" className="drag download" onClick={(e) => this.executeDownload()}>
                                <img src="/common/image/icon/icon_download.png" alt="download" className="download_icon_style" />
                            </button>
                        )}
                    </div>
                    <div className="whitespace"></div>
                </section>
                <main>
                    <Swipeable onSwipedLeft={this.swipeNextFile.bind(this)} onSwipedRight={this.swipePrevFile.bind(this)} preventDefaultTouchmoveEvent={true} trackMouse={true}>
                        <div className="storageview_content">
                            <div className="viewer view_body">
                                <div className="swiper-container">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            {this.state.display_type === 1 && (
                                                // 文章ファイルの場合
                                                <FileViewerText
                                                    src={this.state.view_file_src}
                                                    onError={this.handleTextFetchError.bind(this)}
                                                />
                                            )}
                                            {this.state.display_type === 2 && (
                                                // 文章ファイル（pdf）の場合
                                                <FileViewerPdf view_file_src={this.state.view_file_src} />
                                            )}
                                            {this.state.display_type === 3 && (
                                                // 画像ファイルの場合
                                                <FileViewerImage src={this.state.view_file_src} />
                                            )}
                                            {this.state.display_type === 4 && (
                                                // 音声ファイルの場合
                                                <FileViewerAudio src={this.state.view_file_src} />
                                            )}
                                            {this.state.display_type === 5 && (
                                                // 動画ファイルの場合
                                                <FileViewerVideo src={this.state.view_file_src} />
                                            )}
                                            {this.state.display_type === 98 && (
                                                // WMVファイルまたはWEBMファイルが変換中の場合
                                                <div><br/><label>{this.state.message}</label></div>
                                            )}
                                            {this.state.display_type === 99 && (
                                                // 未対応のファイル形式の場合
                                                <div><br/><label>{this.state.message}</label></div>
                                            )}
                                        </div>
                                    </div>

                                    <div>
                                        {this.state.prev_file_id !== '' && (
                                            <div className="swiper-button-prev">
                                                <span className="arrow arrow-prev left_arrow" onClick={(e) => this.prevFile()}></span>
                                            </div>
                                        )}
                                        {this.state.next_file_id !== '' && (
                                            <div className="swiper-button-next">
                                                <span className="arrow arrow-next right_arrow" onClick={(e) => this.nextFile()}></span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Swipeable>
                </main>
                <MessageDialogForm key="MessageDialogForm"
                    code={this.state.download_error_code}
                    message={this.state.download_error_message}
                />
            </div>
        )
    }

}

export const Swipeable = ({children, ...props}) => {
  const handlers = useSwipeable(props);
  return (<div { ...handlers }>{children}</div>);
}

export default withRouter(FileViewerForm);
