/********************************************************************
 *
 * HTTPステータスコード列挙体.
 * 
 * プラットフォームAPIから返却されるHTTPステータスコードを列挙します.
 *
 *******************************************************************/
class HttpStatusEnum {

    Code = {
        OK: 200,
        BAD_REQUEST: 400,
        UNAUTHORIZED: 401,
        NOT_FOUND: 404,
        INTERNAL_SERVER_ERROR: 500,
        SERVICE_UNAVAILABLE: 503,
    };

}

export default new HttpStatusEnum();
