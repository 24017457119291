import React from 'react';
import {withRouter} from '../../libs/withRouter';
import {ApiEnum, ApiClient, HttpMethod} from '../../api/Api';
import Loading from '../../libs/Loading';

/********************************************************************
 *
 * STORAGE-009 ファイルダウンロード
 *
 *******************************************************************/
class FileDownloadForm extends React.Component {

    /**
     * 指定したファイルまたはフォルダをダウンロードします.
     *
     */
    executeDownload () {
        console.log('executeDownload/start');
        
        // ローディング追加
        Loading.addToElement('execute_download');

        let tmpDownloadTarget = [];
        this.props.selectedList.forEach(function(rec) {
            if (rec['folder_folder_id'] == null) {
                // ファイルの場合
                tmpDownloadTarget.push({
                    id: rec['file_file_id'],
                    file_flg: true,
                });
            } else {
                // フォルダの場合
                tmpDownloadTarget.push({
                    id: rec['folder_folder_id'],
                    file_flg: false,
                });
            }
        })

        let tmpUrlParam = '?';
        tmpDownloadTarget.forEach(function(rec, index) {
            tmpUrlParam
                += 'targets[' + index + '].id=' + rec.id + '&targets[' + index + '].file_flg=' + rec.file_flg + '&'
        })

        ApiClient.storageApiBlob(
            ApiEnum.Code.FILE_DOWNLOAD + encodeURI(tmpUrlParam),
            {},
            HttpMethod.Code.GET)
            .then(retVal => {
                let blob = retVal[0];
                let headers = retVal[1];

                // レスポンスヘッダーからContent-Dispositionの値を取得
                var contentDisposition = headers.get('content-disposition');
	            // ファイル名を抽出
                var searchStr = "attachment; filename*=utf-8''";
                var index = contentDisposition.indexOf(searchStr);
                let fileName = decodeURI(contentDisposition.slice(index + searchStr.length));

                // BLOBファイルをダウンロード
                let link = document.createElement('a');
                if (window.navigator.msSaveBlob) {
                    // for IE
                    window.navigator.msSaveBlob(blob, fileName);
                } else {
                    // for Chrome
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                }

                // 自画面を閉じる
                document.getElementById('storagedownload').classList.remove('is-show');
                // 親画面を再描画
                this.props.reloadParentForm();
            }).catch(err => {
                switch (err.errorCode) {
                    case 'E00007':
                        window.alert(err.errorMessage);
                        break;
                    default:
                        this.props.router.navigate('/app/error', {
                            state: {code: err.errorCode, message: err.errorMessage}
                        });
                        break;
                }
            }).finally(() => {
                // ローディング削除
                Loading.removeFromElement('execute_download');
            });
    }


    /**
     * base64のデータをBLOBへ変換します.
     *
     * @param {base64データ} argBase64 
     * @param {base64データのコンテンツタイプ} argContentsType 
     */
    toBlob(argBase64, argContentsType) {
        let bin = atob(argBase64.replace(/^.*,/, ''));
        let buffer = new Uint8Array(bin.length);

        for (let i = 0; i < bin.length; i++) {
            buffer[i] = bin.charCodeAt(i);
        }

        let blob;
        try {
            blob = new Blob([buffer.buffer], {
                type: argContentsType
            });
        } catch (e) {
            return false;
        }

        return blob;
    }


    /**
     * 画面描画処理.
     *
     */
    render () {
        return (
            <div id="storagedownload" className="modal">
                <div className="modal_content">
                    <p className="title">ダウンロード</p>
                    <div className="actionfield">
                        <div className="actionfield_confirm">
                            <p className="actionfield_head">これをダウンロード</p>
                            <p className="actionfield_body">
                                {
                                    this.props.selectedList.map((record, index) =>
                                        <FileDownloadRow 
                                            key={"filedownload_" + index}
                                            index={index}
                                            dlFileRec={record}
                                        />
                                    )
                                }
                            </p>
                        </div>
                        <div className="actionfield_unit">
                            <button id="execute_download" className="button drag" onClick={(e) => this.executeDownload()}>ダウンロード</button>
                        </div>
                        <p className="caution_description">
                            複数のファイルをまとめてやフォルダをダウンロードすると、ZIP圧縮されてファイルがダウンロードされます。<br/>
                            一部の解凍ソフトでは、ZIPファイルを解凍した時にファイル名が文字化けしてしまう場合があります。<br/>
                                    文字化けしてしまう場合は、UTF-8対応の解凍ソフト（Windows標準の解凍ソフトなど）をお使いください。
                        </p>
                    </div>
                    <div className="button button-close"></div>
                </div>
            </div>
        )
    }
}

/**
 * ダウンロードするファイルの表示用要素
 * @param {プロパティ} props 
 */
const FileDownloadRow = props => {
    const record = props.dlFileRec
    return record['folder_folder_id'] == null ? 
        <span key={record['file_file_id']} className="actionfield_row icon-file">{record['file_file_name']}</span>
        :
        <span key={record['folder_folder_id']} className="actionfield_row icon-folder">{record['folder_folder_name']}</span>
}

export default withRouter(FileDownloadForm);
