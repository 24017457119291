import React, { useEffect, useState } from 'react';
import Encoding from 'encoding-japanese';

const initialContent = '<span>Loading...</span>';

function FileViewerText({ src, onError }) {
    const [content, setContent] = useState(initialContent);

    useEffect(() => {
        setContent(initialContent);
        const xhr = new XMLHttpRequest();
        xhr.open('GET', src, true);
        xhr.responseType = 'arraybuffer';
        xhr.send();
        xhr.onloadend = function(e) {
            if (e.target.status === 200) {
                // テキストファイルの場合は文字化け等が発生するため変換処理を行う
                const htmlString = convertResponseToHtml(this.response);
                setContent(htmlString);
            } else {
                onError(e.target.response);
            }
        };
    }, [src]);

    return (
        <div style={{overflowY : "auto"}}>
            <div
                className="swiper_image_size"
                dangerouslySetInnerHTML={{ __html: content }}
            />
        </div>
    );
}

function convertResponseToHtml(response) {
    const resArray = new Uint8Array(response);
    const unicodeArray = Encoding.convert(resArray, 'UNICODE');
    // Unicode配列を文字列に変換
    const resString = Encoding.codeToString(unicodeArray);

    // 絶対参照に置換
    // HTMLでの描画のため改行コードを<br>タグに変換
    return resString
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;')
        .replace(/\r?\n/g, '<br>');
}

export default FileViewerText;
