import React, { useEffect, useState } from 'react';
import Loading from './FileViewerLoading';

function FileViewerAudio({ src }) {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const audio = new Audio();
        audio.src = src;
        audio.addEventListener('loadeddata', () => setLoading(false));
        audio.load();
    }, [src]);

    return (
        <>
            {loading && <Loading />}
            <audio
                src={src}
                className="swiper_image_size"
                controls
                preload="metadata"
                controlsList="nodownload"
                style={{ opacity: loading ? 0 : 1}}
            />
        </>
    );
}

export default FileViewerAudio;
