import React from 'react';
import {withRouter} from '../../libs/withRouter';
import {ApiEnum, ApiClient, HttpMethod} from '../../api/Api';
import MessageUtil from '../../libs/MessageUtil';
import Loading from '../../libs/Loading';

/********************************************************************
 *
 * STORAGE-004 ファイル検索
 *
 *******************************************************************/
class FileSearchForm extends React.Component {

    /**
     * 初期化処理を行います.
     *
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            message: '',          // システムメッセージ
            search_word: '',      // 検索ワード
            select_radio: 'file', // 選択したラジオボタン
            placeholder: 'フォルダ名、ファイル名を入力', // 検索ワードに表示するplaceholder文字列
        }
    }


    /**
     * 検索ボタン押下時の処理.
     *
     */
    onSearchButton() {
        if (this.state.select_radio === 'file') {
            // ファイル・フォルダ検索
            this.searchFileFolder();
        } else if(this.state.select_radio === 'all') {
            // ファイル全文検索
            this.searchFullText();
        }
    }

    /**
     * ファイル名・フォルダ名で検索します.
     *
     */
    searchFileFolder() {
        const folderId = this.props.current_folder_id;
        const folderName = this.props.current_folder_name;
        const searchWord = this.state.search_word;
        ApiClient.storageApi(
            ApiEnum.Code.FILE_SEARCH, 
            {"folder_id": folderId, "search_word": searchWord}, 
            HttpMethod.Code.GET)
            .then(json => {
                this.props.router.navigate('/app/filesearchresult', {
                    state: {
                        current_folder_id: folderId,
                        current_folder_name: folderName,
                        search_result_list: json['search_result_list'],
                        search_word: searchWord,
                        ai_flg: json['ai_flg']
                    },
                })
                // ファイル・フォルダ検索結果画面から再度呼ばれた場合は自画面を再描画
                if (this.props.is_search_result) {
                    this.props.reloadParentForm(searchWord, json['search_result_list']);
                }
                // 自画面を閉じる
                let modal = document.querySelector('.modal');
                modal.classList.remove('is-show');
                if (json['search_result_list'].length === 0) {
                    window.alert(MessageUtil.getMessage('I00001'));
                }
            }).catch(err => {
                switch (err.errorCode) {
                    case 'E00007':
                    case 'E02036': //検索ワード必須
                    case 'E02037': //検索ワード桁数
                                window.alert(err.errorMessage);
                        break;
                    default:
                        this.props.router.navigate('/app/error', {
                            state: {code: err.errorCode, message: err.errorMessage}
                        });
                        break;
                }
            }).finally(() => {
                // ローディング削除
                Loading.removeFromElement('execute_search');
            });
    }

    /**
     * ファイルに対し全文検索を行います.
     *
     */
    searchFullText() {
        const folderId = this.props.current_folder_id;
        const folderName = this.props.current_folder_name;
        const searchWord = this.state.search_word;

        // ローディング追加
        Loading.addToElement('execute_search');

        ApiClient.storageApi(
            ApiEnum.Code.FILE_FULL_TEXT_SEARCH, 
            {"folder_id": folderId, "search_word": searchWord}, 
            HttpMethod.Code.GET)
            .then(json => {
                this.props.router.navigate('/app/filesearchresult', {
                    state: {
                        current_folder_id: folderId,
                        current_folder_name: folderName,
                        search_result_list: json['search_result_list'],
                        search_word: searchWord,
                        ai_flg: json['ai_flg']
                    },
                })
                // ファイル・フォルダ検索結果画面から再度呼ばれた場合は自画面を再描画
                if (this.props.is_search_result) {
                    this.props.reloadParentForm(searchWord, json['search_result_list']);
                }
                // 自画面を閉じる
                let modal = document.querySelector('.modal');
                modal.classList.remove('is-show');
                if (json['search_result_list'].length === 0) {
                    window.alert(MessageUtil.getMessage('I00001'));
                }
            }).catch(err => {
                switch (err.errorCode) {
                    case 'E00007':
                    case 'E02036': //検索ワード必須
                    case 'E02037': //検索ワード桁数
                            window.alert(err.errorMessage);
                        break;
                    default:
                        this.props.router.navigate('/app/error', {
                            state: {code: err.errorCode, message: err.errorMessage}
                        });
                        break;
                }
            }).finally(() => {
                // ローディング削除
                Loading.removeFromElement('execute_search');
            });
    }

    /**
     * 検索キーワード入力.
     *
     * @param {*} e 
     */
    handleChange = (e) => {
        this.setState({ search_word: e.target.value });
    }

    /**
     * ラジオボタン選択
     *
     * @param {*} e 
     */
    onChangeRadio (e) {
        //選択状態保存
        this.setState({select_radio: e.target.id})
        if (e.target.id === 'file') {
            // ファイル・フォルダ検索
            this.setState({placeholder: 'フォルダ名、ファイル名を入力'})
        } else if(e.target.id === 'all') {
            // ファイル全文検索
            this.setState({placeholder: '検索する文字列を入力'})
        }
    }


    /**
     * 画面描画処理.
     *
     */
    render() {
        return (
            <div>
                <div id="search" className="modal">
                    <div className="modal_content modal-search">
                        <p className="title">フォルダとファイルの検索ができます。</p>
                        <div className="radio" onChange={this.onChangeRadio.bind(this)}>
                            <input id="file" type="radio" name="search" value="file" defaultChecked />
                            <label htmlFor="file">ファイル・フォルダ名検索</label>
                            <input id="all" type="radio" name="search" value="all" />
                            <label htmlFor="all">ファイル全文検索</label>
                        </div>
                        <div className="search">
                            <input type="text" placeholder={this.state.placeholder} onChange={this.handleChange}/>
                            <span>
                                <span className="icon-search"></span>
                                <button id="execute_search" className="loading button button-search" type="submit" onClick={(e) => this.onSearchButton()}>検索</button>
                            </span>
                        </div>
                        <div className="button button-close"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(FileSearchForm);
