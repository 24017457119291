class MessageUtil {

	/**
	 * メッセージIDに紐付くメッセージを取得します.
     *
	 * @param {取得したいメッセージのID}} messageId 
	 */
	getMessage (messageId) {
        let code = {
            'E00004': process.env.REACT_APP_E00004,
            'I00000': process.env.REACT_APP_I00000,
            'I00001': process.env.REACT_APP_I00001.replace(/\\n/g, '\n'),
            'E02010': process.env.REACT_APP_E02010,
            'E02031': process.env.REACT_APP_E02031,
            'E02033': process.env.REACT_APP_E02033,
            'E02034': process.env.REACT_APP_E02034.replace(/\\n/g, '\n'),
            'E02038': process.env.REACT_APP_E02038,
            'I02001': process.env.REACT_APP_I02001,
            'I02002': process.env.REACT_APP_I02002,
            'I02003': process.env.REACT_APP_I02003,
            'I02004': process.env.REACT_APP_I02004,
            'E03013': process.env.REACT_APP_E03013,
            'I09001': process.env.REACT_APP_I09001,
            'I09002': process.env.REACT_APP_I09002,
        };

        return code[messageId];
	}


}

export default new MessageUtil();
