import React from 'react';
import {withRouter} from '../../libs/withRouter';
import StringUtil from '../../libs/StringUtil';
import {ApiEnum, ApiClient, HttpMethod} from '../../api/Api';
import FrontWebSearchForm from "../front_web_search/FrontWebSearchForm.jsx";
import MessageUtil from '../../libs/MessageUtil';

/********************************************************************
 *
 * STORAGE-013 フロントWeb
 *
 *******************************************************************/
class FrontWebForm extends React.Component {

    /**
     * 初期化処理を行います.
     *
     * @param {*} props
     */
    constructor (props) {
        super(props);

        // クエリパラメータからフォルダIDを取得
        let urlParams = new URLSearchParams(window.location.search);
        let urlParamFolderId = '';
        if (urlParams.has('folderId')) {
            urlParamFolderId = urlParams.get('folderId');
        } else if (urlParams.has('folderid')) {
            urlParamFolderId = urlParams.get('folderid');
        }

        this.state = {
            css_type: '',                        // 適用するCSSの種類
            current_folder_root_flg: '',         // 現在表示中のフォルダがrootかどうか
            current_folder_developer_flg: '',    // 現在表示中のフォルダがdeveloperフォルダかどうか
            current_folder_public_flg: '',       // 現在表示中のフォルダが全体公開かどうか
            current_folder_id: urlParamFolderId, // 現在表示中のフォルダーID
            current_folder_name: '',             // 現在表示中のフォルダー名
            current_parent_folder_id: '',        // 現在表示中のフォルダの親フォルダID
            message: '',                         // システムメッセージ
            file_list: [],                       // ファイル一覧
            bookmark_list_file: [],              // お気に入り一覧（ファイル）
            bookmark_list_folder: [],            // お気に入り一覧（フォルダ）
            indicator_display: 'indicator_wrap',
        }
        this.child = [];

        // ファイル・フォルダ一覧を取得
        this.searchListData(urlParamFolderId);
    }

    historyPush(url) {
        this.props.router.navigate(url)
    }

    componentDidUpdate () {
        console.log('componentDidUpdate/start');
        //動的に生成される要素のためにjsを再読込する
        // check.js
        const script1 = document.createElement("script");
        let s1 = document.getElementById('check_script');
        script1.id = "check_script";
        script1.src = "/common/js/check.js";
        script1.async = true;
        if (s1 != null) {
            document.body.replaceChild(script1, s1);
        }
        // bundle.js
        const script2 = document.createElement("script");
        let s2 = document.getElementById('bundle_script');
        script2.id = "bundle_script";
        script2.src = "/common/js/bundle.js";
        script2.async = true;
        if (s2 != null) {
            document.body.replaceChild(script2, s2);
        }

        console.log('componentDidUpdate/end');
    }


    /**
     * 現在表示しているフォルダの親フォルダへ戻ります.
     *
     */
    backToUpperDirectory() {
        console.log('backToUpperDirectory/start');
        this.searchListData(this.state.current_parent_folder_id);
        console.log('backToUpperDirectory/end');
    }


    /**
     * 現在のフォルダーIDをもとにファイル一覧を再検索します.
     * ※このメソッドは子コンポーネントにて利用します
     *
     */
    _didFinish() {
        console.log('_didFinish/start');
        console.log('_didFinish/current_folder_id=' + this.state.current_folder_id)
        this.searchListData(this.state.current_folder_id);
        console.log('_didFinish/end');
    }


    /**
     * 指定したフォルダ直下のファイルおよびフォルダの一覧を取得します.
     *
     * @param {検索するフォルダID} argParamFolderId
     */
    searchListData (argParamFolderId) {
        console.log('searchListData/start');

        let tmpBookmarkList =[];

        ApiClient.storageApi(
            ApiEnum.Code.BOOKMARK_GET,
            {},
            HttpMethod.Code.GET)
            .then(json => {
                tmpBookmarkList = json['bookmark_list'];
            }).catch(err => {
                this.props.history.push({
                    pathname: '/app/error',
                    state: {code: err.errorCode, message: err.errorMessage}
                });
            }
        )

        ApiClient.storageApi(
            ApiEnum.Code.FILE_GET_LIST,
            {},
            HttpMethod.Code.GET,
            argParamFolderId)
            .then(json => {
                // デベロッパーIDによってスタイルを切り替える
                const userInfo = JSON.parse(sessionStorage.getItem('user_info_key'));
                if (userInfo['developerId'] === process.env.REACT_APP_DEVELOPER_ID_NOMURA) {
                    // ノムラ用
                    this.setState({css_type: 'nomura'});
                } else {
                    // 共通
                    this.setState({css_type: 'basic'});
                }

                // ファイルの一覧＋お気に入り登録有無のリスト
                let tmpFileListAddBookmark = [];

                // 取得したファイル一覧
                json['detail'].map((record) => {
                    if (record['file_file_id'] != null) {
                        // ファイルの場合

                        // ブックマークの登録状況を取得
                        let filterResult = tmpBookmarkList.filter(function(bookmarkList) {
                            if (bookmarkList['file_file_id'] === record['file_file_id']) {
                                return true;
                            }
                            return false;
                        });

                        // ファイル一覧情報にブックマークの登録有無の情報を付与
                        let tmp = record;
                        tmp.is_file = true;
                        if (filterResult.length !== 0) {
                            tmp.bookmark_flg = true;
                            tmp.bookmark_id = filterResult[0]['bookmark_id'];
                        } else {
                            tmp.bookmark_flg = false;
                            tmp.bookmark_id = '';
                        }
                        // 明細行へ適用するスタイルを指定
                        tmp.detail_li_class = 'normal_list_img developer_list icon_file';
                        tmpFileListAddBookmark.push(tmp);

                    } else {
                        // フォルダの場合

                        // ブックマークの登録状況を取得
                        let filterResult = tmpBookmarkList.filter(function(bookmarkList) {
                            if (bookmarkList['folder_folder_id'] === record['folder_folder_id']) {
                                return true;
                            }
                            return false;
                        });

                        // ファイル一覧情報にブックマークの登録有無の情報を付与
                        let tmp = record;
                        tmp.is_file = false;
                        if (filterResult.length !== 0) {
                            tmp.bookmark_flg = true;
                            tmp.bookmark_id = filterResult[0]['bookmark_id'];
                        } else {
                            tmp.bookmark_flg = false;
                            tmp.bookmark_id = '';
                        }

                        // フォルダ名に応じて明細行へ適用するスタイルを指定
                        switch (record['folder_folder_name']) {
                            case '防災マニュアル':
                                tmp.detail_li_class = 'normal_folder_img developer_list_tree icon_bosai';
                                break;
                            case '管理のお知らせ':
                                tmp.detail_li_class = 'normal_folder_img developer_list_tree icon_info';
                                break;
                            case '管理に関する届出':
                                tmp.detail_li_class = 'normal_folder_img developer_list_tree icon_shinsei';
                                break;
                            case '取扱説明書':
                                tmp.detail_li_class = 'normal_folder_img developer_list_tree icon_torisetu';
                                break;
                            default:
                                tmp.detail_li_class = 'normal_folder_img developer_list_tree icon_folder';
                                break;
                        }

                        // 説明文の有無に応じて明細行へ適用するスタイルを指定
                        if (record['folder_explanatory_text'] != null && record['folder_explanatory_text'].length !== 0) {
                            tmp.detail_li_class += ' dir_angle_circle';
                        }
                        tmpFileListAddBookmark.push(tmp);

                    }

                    return [];
                });


                // 取得した各種情報をstateへ設定
                this.setState({
                    current_folder_root_flg: json['root_flg'],
                    current_folder_developer_flg: json['developer_folder_flg'],
                    current_folder_public_flg: json['public_folder_flg'],
                    current_folder_id: json['folder_id'],
                    current_folder_name: json['folder_name'],
                    current_parent_folder_id: json['parent_folder_id'],
                    message: '',
                    file_list: tmpFileListAddBookmark,
                    indicator_display: 'indicator_wrap display_none',
                });

                // 最初に開いたフォルダIDをセッションに保存
                if (!sessionStorage.getItem('first_folder_id')) {
                    sessionStorage.setItem('first_folder_id', json['folder_id']);
                }

                this.props.router.navigate('/app/frontweb?folderId=' + json['folder_id'])

            }).catch(err => {
                this.props.router.navigate('/app/error', {
                    state: {code: err.errorCode, message: err.errorMessage}
                });
            }
        )

        console.log('searchListData/end');
    }


    /**
     * 選択したファイルをお気に入りに登録します.
     *
     * @param {ファイルかどうか} argIsFile
     * @param {ファイルまたはフォルダのキー} argKey
     */
    resistFavorite (argIsFile, argKey) {
        console.log('resistFavorite/start')

        //確認ダイアログ
        if (!window.confirm(MessageUtil.getMessage('I09001'))) {
            return;
        }

        let param;
        if (argIsFile) {
            // ファイルの場合
            param = {"file_id": argKey};
        } else {
            // フォルダの場合
            param = {"folder_id": argKey};
        }

        ApiClient.storageApi(
            ApiEnum.Code.BOOKMARK_REGIST,
            param,
            HttpMethod.Code.POST)
            .then(json => {
                // 一覧を再表示
                this.searchListData(this.state.current_folder_id);

            }).catch(err => {
                switch (err.errorCode) {
                    case 'E02024':
                    case 'E04001':
                        window.alert(err.errorMessage);
                        break;
                    default:
                        this.props.router.navigate('/app/error', {
                            state: {code: err.errorCode, message: err.errorMessage}
                        });
                        break;
                }
            }
        )


        console.log('resistFavorite/end');
    }


    /**
     * 選択したファイルをお気に入りから削除します.
     *
     * @param {お気に入りID} argBookmarkId
     */
    deleteFavorite (argBookmarkId) {
        console.log('deleteFavorite/start')

        //確認ダイアログ
        if (!window.confirm(MessageUtil.getMessage('I09002'))) {
            return;
        }

        ApiClient.storageApi(
            ApiEnum.Code.BOOKMARK_DELETE,
            {"bookmark_id": argBookmarkId},
            HttpMethod.Code.DELETE)
            .then(json => {
                // 一覧を再表示
                this.searchListData(this.state.current_folder_id);

            }).catch(err => {
                this.props.router.navigate('/app/error', {
                    state: {code: err.errorCode, message: err.errorMessage}
                });
            }
        )

        console.log('deleteFavorite/end');
    }

    /**
     * 選択したファイルの署名付きURLに移動します.
     *
     * @param {ファイルID} argFileId
     */
    movePresignedUrl (argFileId) {
        console.log('movePresignedUrl/start')

        ApiClient.storageApi(
            ApiEnum.Code.FILE_GET_PRESIGNED_URL,
            {},
            HttpMethod.Code.GET,
            argFileId)
            .then(json => {
                // 署名付きURLに移動
                window.location.href = json['presigned_url'];
            }).catch(err => {
                this.props.router.navigate('/app/error', {
                    state: {code: err.errorCode, message: err.errorMessage}
                });
            }
        )

        console.log('movePresignedUrl/end');
    }

    /**
     * 画面描画処理.
     *
     */
    render () {
        return (
            <div className={this.state.css_type}>
                <div className="filelist_body">
                    <section className="search_area">
                        {/* rootフォルダもしくは最初に開いたフォルダの場合は戻るボタンを非表示 */}
                        {this.state.current_folder_root_flg === false &&
                            sessionStorage.getItem('first_folder_id') != this.state.current_folder_id && (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <a className="left_justified" onClick={(e) => this.backToUpperDirectory()}>
                                <img src="/common/image/icon/icon_left_arrow.png" className="left_arrow_style" />
                            </a>
                        )}
                        <h2>{this.state.current_folder_name}</h2>
                        <input type="button" id="search_popup_open" className="modal_open" value="" />

                        {this.state.current_folder_name !== "" && (
                            <label htmlFor="search_popup_open" className="search_icon_btn js-modal" data-id="search">
                            </label>
                        )}

                    </section>

                    <section className="filelist_area">
                        <ul className="file_folder_list">
                            {this.renderTable()}
                        </ul>
                    </section>

                    {/* 検索ウィンドウ */}
                    <FrontWebSearchForm
                        current_folder_id={this.state.current_folder_id}
                        current_folder_name={this.state.current_folder_name}
                        is_search_result={false}
                    />

                    <div id="indicator" className={this.state.indicator_display}>
                        <div className="indicator_bg">
                            <div className="indicator_icon_wrap">
                                <img src="../common/image/icon/loading.gif" alt="loading" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    /**
     * 画面描画処理（テーブル部分）.
     *
     */
    renderTable() {
        const userInfo = JSON.parse(sessionStorage.getItem('user_info_key'));
        // 行を定義する
        const rows = this.state.file_list.map((record, index) =>
            <>
                {record['is_file'] === true && (
                    // ファイルの場合
                    <li className={record['detail_li_class']}>
                        {/* ノムラ以外はfileviewerに移動 */}
                        {userInfo['developerId'] !== process.env.REACT_APP_DEVELOPER_ID_NOMURA && (
                            <a
                                className="file_item_a"
                                href={
                                    '/app/fileviewer?fileId=' + record['file_file_id'] +
                                    '&folder_id=' + this.state.current_folder_id +
                                    '&is_front=true' +
                                    '&is_paging=true' +
                                    '&caller_path=/app/frontweb?folderId=' + this.state.current_folder_id
                                }
                            >
                                <h3>{record['file_file_name']}</h3>
                            </a>
                        )}
                        {/* ノムラの場合は署名付きURLに移動 */}
                        {userInfo['developerId'] === process.env.REACT_APP_DEVELOPER_ID_NOMURA && (
                            <a className="file_item_a" onClick={(e) => this.movePresignedUrl(record['file_file_id'])}>
                                <h3>{record['file_file_name']}</h3>
                            </a>
                        )}

                        <span className="inner_wrap">
                            <span className="capacity_text">
                                {StringUtil.convertFileSize(record['file_file_size'])}
                            </span>

                            {/* ブックマーク登録済の場合 */}
                            {record['bookmark_flg'] === true && (
                                <span className="inner_wrap favorite_wrap developer_invisible">
                                    <input type="checkbox" name="favorite_mark" id="mark1" className="favorite_mark" defaultChecked />
                                    <label htmlFor="mark1" className="check_on" onClick={(e) => this.deleteFavorite(record['bookmark_id'])}></label>
                                </span>
                            )}
                            {/* ブックマーク未登録の場合 */}
                            {record['bookmark_flg'] === false && (
                                <span className="inner_wrap favorite_wrap developer_invisible">
                                    <input type="checkbox" name="favorite_mark" id="mark1" className="favorite_mark" />
                                    <label htmlFor="mark1" className="check_off" onClick={(e) => this.resistFavorite(record['is_file'], record['file_file_id'])} ></label>
                                </span>
                            )}
                        </span>
                    </li>
                )}

                {record['is_file'] === false && (
                    // フォルダの場合
                    <li className={record['detail_li_class']}>
                        <a
                            className="file_item_a"
                            href={'/app/frontweb?folderId=' + record['folder_folder_id']}
                        >
                            <h3 className="folder_title">{record['folder_folder_name']}</h3>
                            <p className="list_description">{record['folder_explanatory_text']}</p>
                        </a>

                        <span className="inner_wrap developer_invisible">

                            {/* ブックマーク登録済の場合 */}
                            {record['is_file'] === false && record['bookmark_flg'] === true && (
                                <span className="inner_wrap favorite_wrap">
                                    <input type="checkbox" name="favorite_mark" id="mark1" className="favorite_mark" defaultChecked />
                                    <label htmlFor="mark1" className="check_on" onClick={(e) => this.deleteFavorite(record['bookmark_id'])}></label>
                                </span>
                            )}
                            {/* ブックマーク未登録の場合 */}
                            {record['is_file'] === false && record['bookmark_flg'] === false && (
                                <span className="inner_wrap favorite_wrap">
                                    <input type="checkbox" name="favorite_mark" id="mark1" className="favorite_mark" />
                                    <label htmlFor="mark1" className="check_off" onClick={(e) => this.resistFavorite(record['is_file'], record['folder_folder_id'])} ></label>
                                </span>
                            )}
                        </span>
                    </li>
                )}

            </>
        );

        // 表を返却
        return (
            <div>
                {rows}
                <br />
            </div>
        )
    }


}

export default withRouter(FrontWebForm);
