import React from 'react';
import {withRouter} from '../../libs/withRouter';

/********************************************************************
 *
 * メッセージダイアログ
 *
 *******************************************************************/
class MessageDialogForm extends React.Component {

    /**
     * ダイアログを閉じます
     */
    close () {
        document.getElementById('messagedialog').classList.remove('is-show');
    }

    /**
     * エラーコードの文字列を返します
     */
    createErrorCodeString () {
        if (this.props.code && this.props.code !== '') {
            return "(" + this.props.code + ")";
        } else {
            return "";
        }
    }

    /**
     * 画面描画処理.
     *
     */
    render () {
        return (
            <div id="messagedialog" className="modal">
                <div className="modal_content">
                    <div className="messagefield">
                        <p className="message_content">
                            {this.props.message}<br />
                            {this.createErrorCodeString()}
                        </p>
                        <div className="messagefield_unit">
                            <button id="ok" className="button drag" onClick={(e) => this.close()}>OK</button>
                        </div>
                    </div>
                    <button className="button button-close" onClick={(e) => this.close()}></button>
                </div>
            </div>
        )
    }
}

export default withRouter(MessageDialogForm);
