import React from 'react'
import { useDragLayer } from 'react-dnd'
import StringUtil from "../../libs/StringUtil"

// ドラッグ・アンド・ドロップ表示要素のスタイル
const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 999,
    left: 0,
    top: 0
}

/**
 * 掴んでいる要素のスタイルを調整
 * @param {ドラッグ開始位置} initialOffset 
 * @param {ドラッグ移動位置} currentOffset 
 */
function getItemStyles(initialOffset, currentOffset) {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none',
        }
    }
    
    const preview = document.getElementsByClassName('table_preview')[0];
    const previewPosition = preview.getBoundingClientRect();
    let { x, y } = currentOffset
    const transform = `translate(${(x - previewPosition.width) + 40}px, ${y - (previewPosition.height / 2)}px)`
    return {
        width: previewPosition.width,
        height: previewPosition.height,
        transform,
        WebkitTransform: transform,
    }   
}

/**
 * ドラッグ・アンド・ドロップを表示するレイヤー
 * @param {プロパティ}} props 
 */
const FileNanagementDragLayer = props => {
    const {isDragging,item,initialOffset,currentOffset} = useDragLayer(monitor => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }));

    /**
     * 描画
     */
    function renderItem() {
        const record = props.data[item.id];
        const index = props.id;

        // IE11 対応
        // move ボタン以外（リンクや選択テキスト）をドラッグしたとき、
        // IE11 のみ行のドラッグが走ってしまう。
        // そのパターンでは record が undefined になり後続処理でエラーが発生するので、
        // ここで処理をストップして行のドラッグが走らないようにする。
        if (!record) {
            return null;
        }

        return <>
            <tr key={record['folder_folder_id'] == null ? (record['file_file_id']) : (record['folder_folder_id'])}>
                <td>
                    <input type="checkbox" id={"row" + index} data-tying="folder_operation" onChange={(e) => props.checkSelectedRow()} />
                    <label htmlFor={"row" + index}></label>
                </td>
                <td>
                    {/* 検索結果表示中ではない場合は行選択時にビューワを呼び出し */}
                    {record['folder_folder_id'] == null ? (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a className="icon-file" href="#" >
                            {record['file_file_name']}
                        </a>
                    ) : (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a className="icon-folder" href="#">
                            {record['folder_folder_name']}
                        </a>
                    )}
                </td>
                <td>
                    {/* ファイルかフォルダか判定 */}
                    {record['folder_folder_id'] == null ? (
                        <label>
                            {StringUtil.formatDate(new Date(record['file_update_date_time']), 'YYYY-MM-DD hh:mm')}
                            &nbsp;
                            {StringUtil.convertFileSize(record['file_file_size'])}
                        </label>
                    ) : (
                        <label>
                            {StringUtil.formatDate(new Date(record['folder_update_date_time']), 'YYYY-MM-DD hh:mm')}
                        </label>
                    )}
                </td>
                <td>
                    <button className="button button-move"></button>
                </td>
            </tr>
        </>
    }

    if (!isDragging) {
        return null
    }
    return (
        <table style={layerStyles}>
            <thead display='none'>
            </thead>
            <tbody　style={getItemStyles(initialOffset, currentOffset)}>
                {renderItem()}
            </tbody>
        </table>
    )
}
export default FileNanagementDragLayer