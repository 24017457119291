import React from 'react';
import {withRouter} from '../../libs/withRouter';
import {ApiEnum, ApiClient, HttpMethod} from '../../api/Api';
import Loading from '../../libs/Loading';
import MessageUtil from '../../libs/MessageUtil';
/********************************************************************
 *
 * STORAGE-006 ファイル名更新
 *
 *******************************************************************/
class FileUpdateForm extends React.Component {

    /**
     * コンストラクタ.
     *  
     * 初期化処理を行います.
     *
     * @param {*} props 
     */
    constructor (props) {
        super(props);

        this.state = {
            file_id: null,
            new_file_name: '',  // 変更後のファイル名
        };
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    componentWillUnmount() {
        this.props.onRef(null)
    }

    /**
     * State初期化.
     *
     * stateの初期化を行います.
     *
     */
    initFileUpdateState(fileId, fileName) {
        this.setState({
            file_id: fileId,
            new_file_name: fileName == null ? '' : fileName,
        });
    }

    /**
     * 入力ファイル名更新.
     *
     * ファイル名のStateを更新します.
     *
     */
    inputFileName (e) {
        this.setState({new_file_name: e.target.value});
    }

    /**
     * モーダル閉じる
     */
    closeModal() {
        // 自画面を閉じる
        document.getElementById('fileupdate').classList.remove('is-show');
        //閉じるときにリセット
        this.initFileUpdateState();
    }

    /**
     * ファイル更新ボタン押下
     */
    onUpdateFileButton() {
        const fileId = this.state.file_id;
        const fileName = this.state.new_file_name;
        const currentFolderId = this.props.folderId;
        
        ApiClient.storageApi(
            ApiEnum.Code.FILECHECK_NAME, {
                "file_id": fileId,
                "file_name": fileName,
                "folder_id": currentFolderId,
            }, HttpMethod.Code.GET)
        .then(json => {
            const isDuplicate = json['check_rslt'];
            if (isDuplicate) { //重複している場合メッセージダイアログ
                if (!window.confirm(MessageUtil.getMessage('I02003'))) {
                    //キャンセル（何もしない）
                } else {
                    //OK押下の場合、重複状態でファイル更新
                    this.executeUpdate(true)
                }
            } else { 
                //ファイル名更新
                this.executeUpdate(false)
            }
        }).catch(err => {
            this.props.router.navigate('/app/error', {
                state: {code: err.errorCode, message: err.errorMessage}
            });
        });
    }

    /**
     * 指定されたファイルの名称を更新します.
     * @param {ファイル名の重複を許可するか true：許可} isDuplicate 
     */
    executeUpdate (isAllowDuplicate) {
        //ローディング追加
        Loading.addToElement('file_update_button');

        ApiClient.storageApi(
            ApiEnum.Code.FILE_UPDATE, 
            {
                "file_id": this.state.file_id,
                "file_name": this.state.new_file_name,
                "override_flg": isAllowDuplicate
            }, 
            HttpMethod.Code.PUT)
            .then(json => {
                // 自画面を閉じる
                this.closeModal();
                // 親画面を再描画
                this.props.reloadParentForm();
                return;
            }).catch(err => {
                switch (err.errorCode) {
                    case 'E00007':
                    case 'E02026': //ファイル名必須
                    case 'E02026': //ファイル名桁数
                    case 'E02029': //使用不可文字
                    case 'E03019': //使用不可文字
                        window.alert(err.errorMessage);
                        break;
                    default:
                        this.props.router.navigate('/app/error', {
                            state: {code: err.errorCode, message: err.errorMessage}
                        });
                        break;
                }
            }).finally(() => {
                // ローディング削除
                Loading.removeFromElement('file_update_button');
            });
    }


    /**
     * 画面描画処理.
     *
     */
    render () {
        return (
            <div id="fileupdate" className="modal">
                <div className="modal_content">
                    <p className="title">名前変更</p>
                    <div className="inputfield">
                        <span>
                            <label>表示ファイル名</label>
                            <input id="foldername" type="text" placeholder="ファイル名を入力" maxLength="50" value={this.state.new_file_name} onChange={(e) => this.inputFileName(e)}/>
                            <p className="count__text">推奨文字数残り<span id="textlength1">
                                    { 40 - this.state.new_file_name.length }</span>文字</p>
                        </span>
                        <div className="inputfield_unit">
                            <button id="file_update_button" className="button drag" onClick={(e) => this.onUpdateFileButton()}>更新</button>
                        </div>
                    </div>
                    <div className="button button-close" onClick={(e) => this.closeModal()}></div>
                </div>
            </div>
        )
    }


}

export default withRouter(FileUpdateForm);
