import React, { useEffect, useState } from 'react';
import Loading from './FileViewerLoading';

function FileViewerImage({ src }) {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const image = new Image();
        image.src = src;
        image.onload = () => setLoading(false);
    }, [src]);

    return (
        <>
            {loading && <Loading />}
            <img
                src={src}
                alt="0"
                className="swiper_image_size"
                style={{ opacity: loading ? 0 : 1}}
            />
        </>
    );
}

export default FileViewerImage;
