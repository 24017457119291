import React from 'react';
import {withRouter} from '../../libs/withRouter';
import StringUtil from '../../libs/StringUtil';
import {ApiEnum, ApiClient, HttpMethod} from '../../api/Api';
import MessageUtil from '../../libs/MessageUtil';
import FrontWebSearchForm from "../front_web_search/FrontWebSearchForm.jsx";

/********************************************************************
 *
 * STORAGE-015 ファイル・フォルダ検索結果（フロント）
 *
 *******************************************************************/
class FrontWebSearchResultForm extends React.Component {

    /**
     * コンストラクタ.
     *
     * 初期化処理を行います.
     *
     * @param {*} props
     */
    constructor(props) {
        super(props);

        // デベロッパーIDによってスタイルを切り替える
        // 本画面は別画面にてAPIが発行されてから遷移する画面となるため、ここでsessionStorageを取得する
        const userInfo = JSON.parse(sessionStorage.getItem('user_info_key'));
        let tmpCssType;
        if (userInfo['developerId'] === process.env.REACT_APP_DEVELOPER_ID_NOMURA) {
            // ノムラ用
            tmpCssType = 'nomura';
        } else {
            // 共通
            tmpCssType = 'basic';
        }
        console.log('cssType=' + tmpCssType);

        this.state = {
            css_type: tmpCssType,
            current_folder_id: this.props.router.location.state.current_folder_id,
            current_folder_name: this.props.router.location.state.current_folder_name,
            message: '',
            search_result_list: this.props.router.location.state.search_result_list,
            selected_row_list: [],
            search_word: this.props.router.location.state.search_word,
        }

        //動的に生成される要素のためにjsを再読込する
        // check.js
        const script1 = document.createElement("script");
        let s1 = document.getElementById('check_script');
        script1.id = "check_script";
        script1.src = "/common/js/check.js";
        script1.async = true;
        if (s1 != null) {
            document.body.replaceChild(script1, s1);
        }
        // bundle.js
        const script2 = document.createElement("script");
        let s2 = document.getElementById('bundle_script');
        script2.id = "bundle_script";
        script2.src = "/common/js/bundle.js";
        script2.async = true;
        if (s2 != null) {
            document.body.replaceChild(script2, s2);
        }
    }



    /**
     * 現在のフォルダーIDをもとにファイル一覧を再検索します.
     *
     */
    _didFinishSearch(argSearchWord, argSearchResultList) {
        this.setState({
            message: '',
            search_result_list: argSearchResultList,
            selected_row_list: [],
            search_word: argSearchWord,
        })
    }


    /**
     * ファイル管理画面への遷移処理（戻るボタン押下時）.
     *
     */
    backFileManagement() {
        this.props.router.navigate('/app/frontweb?folderId=' + this.state.current_folder_id);
    }


    /**
     * ファイル管理画面への遷移処理（行選択時）.
     *
     * @param {ファイル管理画面で表示するフォルダID} argFolderId
     */
    moveFileManagement(argFolderId) {
        this.props.router.navigate('/app/frontweb?folderId=' + argFolderId);
    }


    /**
     * 選択したファイルをお気に入りに登録します.
     *
     * @param {ファイルかどうか} argIsFile
     * @param {ファイルまたはフォルダのキー} argKey
     */
    resistFavorite (argIsFile, argKey) {
        console.log('resistFavorite/start')

        //確認ダイアログ
        if (!window.confirm(MessageUtil.getMessage('I09001'))) {
            return;
        }

        let param;
        if (argIsFile) {
            // ファイルの場合
            param = {"file_id": argKey};
        } else {
            // フォルダの場合
            param = {"folder_id": argKey};
        }

        ApiClient.storageApi(
            ApiEnum.Code.BOOKMARK_REGIST,
            param,
            HttpMethod.Code.POST)
            .then(json => {
                // 画面を再描画
                this.getBookmarkData();

            }).catch(err => {
                switch (err.errorCode) {
                    case 'E02024':
                    case 'E04001':
                        window.alert(err.errorMessage);
                        break;
                    default:
                        this.props.router.navigate('/app/error', {
                            state: {code: err.errorCode, message: err.errorMessage}
                        });
                        break;
                }
            }
        )

        console.log('resistFavorite/end');
    }


    /**
     * 選択したファイルをお気に入りから削除します.
     *
     * @param {お気に入りID} argBookmarkId
     */
    deleteFavorite (argBookmarkId) {
        console.log('deleteFavorite/start')

        //確認ダイアログ
        if (!window.confirm(MessageUtil.getMessage('I09002'))) {
            return;
        }

        ApiClient.storageApi(
            ApiEnum.Code.BOOKMARK_DELETE,
            {"bookmark_id": argBookmarkId},
            HttpMethod.Code.DELETE)
            .then(json => {
                // 画面を再描画
                this.getBookmarkData();

            }).catch(err => {
                this.props.router.navigate('/app/error', {
                    state: {code: err.errorCode, message: err.errorMessage}
                });
            }
        )

        console.log('deleteFavorite/end');
    }


    /**
     * 検索結果リストに紐付くお気に入り情報を再取得します.
     *
     */
    getBookmarkData () {
        console.log('getBookmarkData/start');

        let tmpBookmarkList =[];

        ApiClient.storageApi(
            ApiEnum.Code.BOOKMARK_GET,
            {},
            HttpMethod.Code.GET)
            .then(json => {
                tmpBookmarkList = json['bookmark_list'];

                // ファイルの一覧＋お気に入り登録有無のリスト
                let tmpFileListAddBookmark = [];
                // ファイルビューワでの遷移用リスト
                let tmpFileListForViewer = [];

                // 取得したファイル一覧
                this.state.search_result_list.map((record) => {
                    if (record['file_file_id'] != null) {
                        // ファイルの場合

                        // ファイルビューワでの遷移対象リストへ登録
                        tmpFileListForViewer.push(record['file_file_id']);

                        // ブックマークの登録状況を取得
                        let filterResult = tmpBookmarkList.filter(function(bookmarkList) {
                            if (bookmarkList['file_file_id'] === record['file_file_id']) {
                                return true;
                            }
                            return false;
                        });

                        // ファイル一覧情報にブックマークの登録有無の情報を付与
                        let tmp = record;
                        tmp.is_file = true;
                        if (filterResult.length !== 0) {
                            tmp.bookmark_flg = true;
                            tmp.bookmark_id = filterResult[0]['bookmark_id'];
                        } else {
                            tmp.bookmark_flg = false;
                            tmp.bookmark_id = '';
                        }
                        // 明細行へ適用するスタイルを指定
                        tmp.detail_li_class = 'normal_list_img developer_list icon_file';
                        tmpFileListAddBookmark.push(tmp);

                    } else {
                        // フォルダの場合

                        // ブックマークの登録状況を取得
                        let filterResult = tmpBookmarkList.filter(function(bookmarkList) {
                            if (bookmarkList['folder_folder_id'] === record['folder_folder_id']) {
                                return true;
                            }
                            return false;
                        });

                        // ファイル一覧情報にブックマークの登録有無の情報を付与
                        let tmp = record;
                        tmp.is_file = false;
                        if (filterResult.length !== 0) {
                            tmp.bookmark_flg = true;
                            tmp.bookmark_id = filterResult[0]['bookmark_id'];
                        } else {
                            tmp.bookmark_flg = false;
                            tmp.bookmark_id = '';
                        }

                        // フォルダ名に応じて明細行へ適用するスタイルを指定
                        switch (record['folder_folder_name']) {
                            case '防災マニュアル':
                                tmp.detail_li_class = 'normal_folder_img developer_list_tree icon_bosai';
                                break;
                            case '管理のお知らせ':
                                tmp.detail_li_class = 'normal_folder_img developer_list_tree icon_info';
                                break;
                            case '管理に関する届出':
                                tmp.detail_li_class = 'normal_folder_img developer_list_tree icon_shinsei';
                                break;
                            case '取扱説明書':
                                tmp.detail_li_class = 'normal_folder_img developer_list_tree icon_torisetu';
                                break;
                            default:
                                tmp.detail_li_class = 'normal_folder_img developer_list_tree icon_folder';
                                break;
                        }

                        // 説明文の有無に応じて明細行へ適用するスタイルを指定
                        if (record['folder_explanatory_text'].length !== 0) {
                            tmp.detail_li_class += ' dir_angle_circle';
                        }
                        tmpFileListAddBookmark.push(tmp);

                    }

                    return [];
                });

                console.log(tmpFileListAddBookmark);

                // 取得した情報をstateへ設定
                this.setState({
                    search_result_list: tmpFileListAddBookmark,
                });

            }).catch(err => {
                this.props.router.navigate('/app/error', {
                    state: {code: err.errorCode, message: err.errorMessage}
                });
                return;
            }
        )

        console.log('getBookmarkData/end');
    }


    /**
     * ファイル管理画面への遷移処理（戻るボタン押下時）.
     *
     */
    backFrontWeb() {
        this.props.router.navigate('/app/frontweb?folderId=' + this.state.current_folder_id);
    }


    /**
     * 画面描画処理.
     *
     */
    render () {
        return (
            <div className={this.state.css_type}>
                <div className="filelist_body">
                    <section className="search_area">
                        <a className="left_justified" onClick={(e) => this.backFrontWeb()}>
                            <img src="/common/image/icon/icon_left_arrow.png" className="left_arrow_style" />
                        </a>
                        <h2>{this.state.search_word + 'の検索結果'}</h2>
                        <input type="button" id="search_popup_open" className="modal_open" value="" />
                        <label htmlFor="search_popup_open" className="search_icon_btn js-modal" data-id="search">
                        </label>
                    </section>

                    <section className="filelist_area">
                        <ul className="file_folder_list">
                            {this.renderTable()}
                        </ul>
                    </section>

                    {/* 検索ウィンドウ */}
                    <FrontWebSearchForm
                        current_folder_id={this.state.current_folder_id}
                        current_folder_name={this.state.current_folder_name}
                        is_search_result={true}
                        reloadParentForm={this._didFinishSearch.bind(this)}
                    />

                </div>

            </div>
        );
    }


    /**
     * 画面描画処理（テーブル部分）.
     *
     */
    renderTable() {

        // 行を定義する
        const rows = this.state.search_result_list.map((record, index) =>
            <>
                {record['is_file'] === true && (
                    // ファイルの場合
                    <li className={record['detail_li_class']}>
                        <a
                            className="file_item_a"
                            href={
                                '/app/fileviewer?fileId=' + record['file_file_id'] +
                                '&is_front=true' +
                                '&caller_path=/app/frontweb?folderId=' + this.state.current_folder_id
                            }
                        >
                            <h3>{record['file_file_name']}</h3>
                        </a>

                        <span className="inner_wrap">
                            <span className="capacity_text">
                                {StringUtil.convertFileSize(record['file_file_size'])}
                            </span>

                            {/* ブックマーク登録済の場合 */}
                            {record['bookmark_flg'] === true && (
                                <span className="inner_wrap favorite_wrap developer_invisible">
                                    <input type="checkbox" name="favorite_mark" id="mark1" className="favorite_mark" defaultChecked />
                                    <label htmlFor="mark1" className="check_on" onClick={(e) => this.deleteFavorite(record['bookmark_id'])}></label>
                                </span>
                            )}
                            {/* ブックマーク未登録の場合 */}
                            {record['bookmark_flg'] === false && (
                                <span className="inner_wrap favorite_wrap developer_invisible">
                                    <input type="checkbox" name="favorite_mark" id="mark1" className="favorite_mark" />
                                    <label htmlFor="mark1" className="check_off" onClick={(e) => this.resistFavorite(record['is_file'], record['file_file_id'])} ></label>
                                </span>
                            )}
                        </span>
                    </li>
                )}


                {record['is_file'] === false && (
                    // フォルダの場合
                    <li className={record['detail_li_class']}>
                        <a
                            className="file_item_a"
                            href={'/app/frontweb?folderId=' + record['folder_folder_id']}
                        >
                            <h3 className="folder_title">{record['folder_folder_name']}</h3>
                            <p className="list_description">{record['folder_explanatory_text']}</p>
                        </a>

                        <span className="inner_wrap developer_invisible">

                            {/* ブックマーク登録済の場合 */}
                            {record['is_file'] === false && record['bookmark_flg'] === true && (
                                <span className="inner_wrap favorite_wrap">
                                    <input type="checkbox" name="favorite_mark" id="mark1" className="favorite_mark" defaultChecked />
                                    <label htmlFor="mark1" className="check_on" onClick={(e) => this.deleteFavorite(record['bookmark_id'])}></label>
                                </span>
                            )}
                            {/* ブックマーク未登録の場合 */}
                            {record['is_file'] === false && record['bookmark_flg'] === false && (
                                <span className="inner_wrap favorite_wrap">
                                    <input type="checkbox" name="favorite_mark" id="mark1" className="favorite_mark" />
                                    <label htmlFor="mark1" className="check_off" onClick={(e) => this.resistFavorite(record['is_file'], record['folder_folder_id'])} ></label>
                                </span>
                            )}
                        </span>
                    </li>
                )}

            </>
        );

        // 表を返却
        return (
            <div>
                {rows}
                <br />
            </div>
        )
    }


}

export default withRouter(FrontWebSearchResultForm);
