import HttpMethod from "../enums/HttpMethod"
import HttpStatusEnum from '../enums/HttpStatusEnum.jsx';
import ApiError from '../api/ApiError';
import MessageUtil from '../libs/MessageUtil';
import ApiEnum from '../enums/ApiEnum';


class ApiClient {

	/**
	 * ストレージのAPIにアクセス（JSON）
	 * @param {どのAPIにアクセスするか} url 
	 * @param {パラメータ} params 
	 * @param {GETやPOSTなど} method 
	 * @param {変数がURLになる場合指定} paths 
	 * import {ApiEnum, ApiClient, HttpMethod} from 'api/Api' して使ってください
	 */
	async storageApi(url, params, method, ...paths) {
		// sessionStorageにuserInfoがないときのみ実行
		let fetchGetUser = fetch(ApiEnum.Code.GET_USER_INFO, {
			method: HttpMethod.Code.GET,
			credentials: 'same-origin',
			headers: this.createHeaders()});
		
		let getUserPromise = fetchGetUser.then(response => {
			//一旦テキストにしてレスポンスを展開
			const responseBodyPromise = response.text()
			//レスポンスをjsonに変換 レスポンスない場合空のjson生成 status200と比較を行い次のthenへ
			return responseBodyPromise.then(text => ({
				body: text ? JSON.parse(text) : {}, 
				status: response.status
			}))
		})
		.then(({ body, status }) => {
			//200OKの場合のみ正常系
			if (status === HttpStatusEnum.Code.OK) {
				return body;
			} else {
				if (this._isValidJson(body)) {
					//jsonできてたら独自のエラー形式にする
					const apiError = new ApiError(body)
					throw apiError
				} else {
					//jsonパースできなかったら汎用エラー
					throw this._createCommonError();
				}
			}
		})
		.catch(e => {
			if (e instanceof ApiError) {
				throw e 
			} else {
				//そもそもサーバーからエラーなら汎用エラー
				throw this._createCommonError();
			}
		});
		try {
			let userInfo = await getUserPromise; // 待つ
			if (userInfo != null) {
				//json文字列として登録
				//JSON.parse(sessionStorage.getItem('user_info_key'))で取得
				sessionStorage.setItem('user_info_key', JSON.stringify(userInfo));
			}
		} catch(errorUserInfo) {
			if (errorUserInfo instanceof ApiError) {
				throw errorUserInfo 
			} else {
				throw this._createCommonError();
			}
		}

		let urlStr = url
		for (let path of paths) {
            urlStr = urlStr + '/' + path
		}
		let fetchObj
		switch (method) {
			case HttpMethod.Code.GET:
			case HttpMethod.Code.DELETE:
				urlStr = urlStr + this._queryString(params)
				fetchObj = fetch(urlStr, {
					method: method,
					credentials: 'same-origin',
					headers: this.createHeaders()})
				break
			default:
				const jsonBody = JSON.stringify(params);
				fetchObj = fetch(urlStr, { 
					method: method,
					credentials: 'same-origin',
					headers: this.createHeaders(),
					body: jsonBody})
				break
		}
		return fetchObj
		.then(response => {
			//一旦テキストにしてレスポンスを展開
			const responseBodyPromise = response.text()
			//レスポンスをjsonに変換 レスポンスない場合空のjson生成 status200と比較を行い次のthenへ
			return responseBodyPromise.then(text => ({
				body: text ? JSON.parse(text) : {}, 
				status: response.status
			}))
		})
		.then(({ body, status }) => {
			//200OKの場合のみ正常系
			if (status === HttpStatusEnum.Code.OK) {
				return body;
			} else {
				if (this._isValidJson(body)) {
					//jsonできてたら独自のエラー形式にする
					const apiError = new ApiError(body)
					throw apiError
				} else {
					//jsonパースできなかったら汎用エラー
					throw this._createCommonError();
				}
			}
		})
		.catch(e => {
			if (e instanceof ApiError) {
				throw e 
			} else {
				//そもそもサーバーからエラーなら汎用エラー
				throw this._createCommonError();
			}
		});

	}

	/**
	 * ストレージのAPIにアクセス（BLOB）
	 * @param {どのAPIにアクセスするか} url 
	 * @param {パラメータ} params 
	 * @param {GETやPOSTなど} method 
	 * @param {変数がURLになる場合指定} paths 
	 * import {ApiEnum, ApiClient, HttpMethod} from 'api/Api' して使ってください
	 */
	async storageApiBlob(url, params, method, ...paths) {
		// sessionStorageにuserInfoがないときのみ実行
		let fetchGetUser = fetch(ApiEnum.Code.GET_USER_INFO, {
			method: HttpMethod.Code.GET,
			credentials: 'same-origin',
			headers: this.createHeaders()});
		
		let getUserPromise = fetchGetUser.then(response => {
			//一旦テキストにしてレスポンスを展開
			const responseBodyPromise = response.text()
			//レスポンスをjsonに変換 レスポンスない場合空のjson生成 status200と比較を行い次のthenへ
			return responseBodyPromise.then(text => ({
				body: text ? JSON.parse(text) : {}, 
				status: response.status
			}))
		})
		.then(({ body, status }) => {
			//200OKの場合のみ正常系
			if (status === HttpStatusEnum.Code.OK) {
				return body;
			} else {
				if (this._isValidJson(body)) {
					//jsonできてたら独自のエラー形式にする
					const apiError = new ApiError(body)
					throw apiError
				} else {
					//jsonパースできなかったら汎用エラー
					throw this._createCommonError();
				}
			}
		})
		.catch(e => {
			if (e instanceof ApiError) {
				throw e 
			} else {
				throw this._createCommonError();
			}
		});
		try {
			let userInfo = await getUserPromise; // 待つ
			if (userInfo != null) {
				//json文字列として登録
				//JSON.parse(sessionStorage.getItem('user_info_key'))で取得
				sessionStorage.setItem('user_info_key', JSON.stringify(userInfo));
			}
		} catch(errorUserInfo) {
			if (errorUserInfo instanceof ApiError) {
				throw errorUserInfo 
			} else {
				throw this._createCommonError();
			}
		}

		let urlStr = url
		for (let path of paths) {
            urlStr = urlStr + '/' + path
		}
		let fetchObj
		let testHd = new Headers({});
		testHd.append("Content-Type", "application/x-www-form-urlencoded");
		switch (method) {
			case HttpMethod.Code.GET:
			case HttpMethod.Code.DELETE:
				urlStr = urlStr + this._queryString(params)
				fetchObj = fetch(urlStr, {
					method: method,
					credentials: "same-origin",
					headers: this.createHeaders()})
					// headers: testHd})
				break
			default:
				const jsonBody = JSON.stringify(params);
				fetchObj = fetch(urlStr, { 
					method: method,
					credentials: "same-origin",
					headers: this.createHeaders(),
					body: jsonBody})
				break
		}
		return fetchObj
		.then(response => {
		  if (response.status === HttpStatusEnum.Code.OK) {
        const responseBodyPromise = response.blob();
        return responseBodyPromise.then(blob => ({
          body: blob ? blob : null, 
          status: response.status,
          headers: response.headers
        }));
			} else {
			  //一旦テキストにしてレスポンスを展開
        const responseBodyPromise = response.text()
        return responseBodyPromise.then(text => ({
          body: text ? JSON.parse(text) : {}, 
          status: response.status,
          headers: response.headers
        }))
			}
		})
		.then(({ body, status, headers }) => {
			//200OKの場合のみ正常系
			if (status === HttpStatusEnum.Code.OK) {
				let retVal = [body, headers];
				return retVal;
			} else {
				if (this._isValidJson(body)) {
					//jsonできてたら独自のエラー形式にする
					const apiError = new ApiError(body)
					throw apiError
				} else {
					//jsonパースできなかったら汎用エラー
					throw this._createCommonError();
				}
			}
		})
		.catch(e => {
			if (e instanceof ApiError) {
				throw e 
			} else {
				//そもそもサーバーからエラーなら汎用エラー
				throw this._createCommonError();
			}
		});

	}

	/**
	 * ヘッダー作成
	 */
	createHeaders(){
		let tmpHeader = new Headers({});
		tmpHeader.append("Content-Type", "application/json");
		return tmpHeader;
	}

	/**
	 * GETパラメーター作成
	 */
	_queryString(query = {}) {
		// get array of key value pairs ([[k1, v1], [k2, v2]])
		const qs = Object.entries(query)
		// filter pairs with undefined value
		.filter(pair => pair[1] !== undefined)
		// encode keys and values, remove the value if it is null, but leave the key
		.map(pair => pair.filter(i => i !== null).map(encodeURIComponent).join('='))
		.join('&');

		return qs && '?' + qs;
	}

	_isValidJson(value) {
		if (value.constructor === ({}).constructor) {
			return true
		}

		try {
			JSON.parse(value)
		} catch (e) {
			return false
		}
		return true
	}

	_createCommonError() {
		return new ApiError({
			"errorMessage": MessageUtil.getMessage('E00004'),
			"errorCode": 'E00004',
			"fieldErrorList": []
		});
	}
	
}
export default new ApiClient();
