import React from 'react';
import {withRouter} from '../../libs/withRouter';
import MessageUtil from '../../libs/MessageUtil';

/********************************************************************
 *
 * STORAGE-xxx エラー画面
 * 
 * 以下のようにすることでエラーコードとメッセージを表示できます
 * this.props.router.navigate('/app/error', {
 *     state: {code: err.errorCode, message: err.errorMessage}
 * });
 * 
 * stateを指定しない場合はE00004（予期せぬ例外）となります
 *
 *
 *******************************************************************/
class ErrorForm extends React.Component {

    /**
     * 初期化処理を行います.
     *
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        
        document.title = 'ストレージ';

        const messagaeId = 'E00004';
        this.state = {
            code: messagaeId,
            message: MessageUtil.getMessage(messagaeId),
        }
    }

    /**
     * 前の画面へ
     *
     */
    backToUpperDirectory() {
        console.log('backToUpperDirectory/start');
        this.props.router.navigate(-1);
        console.log('backToUpperDirectory/end');
    }

    /**
     * エラーコード表示用文字列生成
     */
    createErrorCodeString() {
        let errorCodeStr = '';
        if (this.props.router.location.state != null) {
            //前画面からエラー情報を受け取っている場合
            errorCodeStr += this.props.router.location.state.code;
        } else if (this.props.code != null) {
            //ページそのものがないとき(App.jsxからのルーティングエラー)
            errorCodeStr += this.props.code;
        } else {
            //それ以外の汎用エラー
            errorCodeStr += this.state.code;
        }
        return "(" + errorCodeStr + ")";
    }

    /**
     * エラーメッセージ表示用文字列生成
     */
    createErrorMessageString() {
        let errorMessageStr = '';
        if (this.props.router.location.state != null) {
            //前画面からエラー情報を受け取っている場合
            errorMessageStr += this.props.router.location.state.message;
        } else if (this.props.message != null) {
            //ページそのものがないとき(App.jsxからのルーティングエラー)
            errorMessageStr += this.props.message;
        } else {
            //それ以外の汎用エラー
            errorMessageStr += this.state.message;
        }
        return errorMessageStr;
    }

    /**
     * 画面描画処理.
     *
     */
    render() {
        return (
            <div className="body_style">
                <main>
                    <section className="user_error">
                        <p className="error_message">
                            {
                                this.createErrorMessageString()
                            }
                        </p>
                        <p className="error_number">
                            {
                                this.createErrorCodeString()
                            }
                        </p>
                    </section>
                </main>
            </div>
        )
    }
}

export default withRouter(ErrorForm);
