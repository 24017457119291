import React from 'react';
import {withRouter} from '../../libs/withRouter';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from 'react-dnd-touch-backend';
import { touchable } from '../../libs/Touchable';
import {ApiEnum, ApiClient, HttpMethod} from '../../api/Api';
import MessageUtil from '../../libs/MessageUtil';
import Loading from '../../libs/Loading';
import FileSearchForm from "../file_search/FileSearchForm.jsx";
import FileMoveForm from "../file_move/FileMoveForm.jsx";
import FileUpdateForm from "../file_update/FileUpdateForm.jsx";
import FileDownloadForm from "../file_download/FileDownloadForm.jsx";
import FileDeleteForm from "../file_delete/FileDeleteForm.jsx";
import FolderUpdateForm from "../folder_update/FolderUpdateForm.jsx";
import FolderCreateForm from "../folder_update/FolderCreateForm.jsx";
import FileManagerRow from "./FileManagerRow";
import FileDropBox from "./FileManagementFileBox";
import FileManagementDragLayer from "./FileManagementDragLayer";
import Cookies from 'js-cookie';

const isIE = typeof DataTransfer.prototype.setDragImage === 'undefined';

/********************************************************************
 *
 * STORAGE-003 ファイル管理
 *
 *******************************************************************/
class FileManagementForm extends React.Component {

    /**
     * 初期化処理を行います.
     *
     * @param {*} props 
     */
    constructor (props) {
        super(props);

        document.title = 'ファイル管理｜ストレージ';

        // クエリパラメータからフォルダIDを取得
        let urlParam = new URLSearchParams(window.location.search).get('folderid');
        if (urlParam === null) {
            urlParam = '';
        }

        this.state = {
            current_folder_root_flg: '',      // 現在表示中のフォルダがrootかどうか
            current_folder_developer_flg: '', // 現在表示中のフォルダがdeveloperフォルダかどうか
            current_folder_public_flg: '',    // 現在表示中のフォルダが全体公開かどうか
            current_folder_id: urlParam,      // 現在表示中のフォルダーID
            current_folder_name: '',          // 現在表示中のフォルダー名
            current_parent_folder_id: '',     // 現在表示中のフォルダの親フォルダID
            ai_user_flg: '',                  // 新AI管理員サービスの権限チェック
            message: '',                      // システムメッセージ
            file_list: [],                    // ファイル一覧
            breadcrumbs: [],                  // パンくずリスト
            selected_row_list: [],            // 選択行リスト
            token: Cookies.get('XSRF-TOKEN'),
        }
        this.child = [];
    }

    /**
     * コンポーネントがマウントされた場合に実行される処理です.
     *
     */
    componentDidMount () {
        console.log('componentDidMount/start');

        // 一覧を取得
        this.searchListData(this.state.current_folder_id);

        this.reorder = this.reorder.bind(this);
        this.checkSelectedRow = this.checkSelectedRow.bind(this);
        this.historyPush = this.historyPush.bind(this);
    }

    reorder(num, id, index) {
        const fromItem = this.state.file_list[id]
        const toItem = this.state.file_list[index]

        const fileId = fromItem.file_file_id
        const folderId = fromItem.folder_folder_id
        const fromSortOrder = fromItem.sort_order
        const toSortOrder = toItem.sort_order
        let sortOrder = fromSortOrder > toSortOrder ? toSortOrder - 1 : toSortOrder + 1
        if (index + 1 === this.state.file_list.length) {
            sortOrder = 0
        }

        ApiClient.storageApi(
            ApiEnum.Code.SORT_ORDER_UPDATE, 
            {
                "file_id": fileId,
                "folder_id" : folderId, 
                "sort_order": sortOrder
            },
            HttpMethod.Code.PUT)
            .then(json => {
                // 並び替え後のファイル一覧を取得
                this.searchListData(this.state.current_folder_id);
                return;
            }).catch(err => {
                this.props.router.navigate('/app/error', {
                    state: {code: err.errorCode, message: err.errorMessage}
                });
            }
        )
    }

    historyPush(url) {
        this.props.router.navigate(url)
    }

    componentDidUpdate () {
        //動的に生成される要素のためにjsを再読込する
        // check.js
        const script1 = document.createElement("script");
        let s1 = document.getElementById('check_script');
        script1.id = "check_script";
        script1.src = "/common/js/check.js";
        script1.async = true;
        if (s1 != null) {
            document.body.replaceChild(script1, s1);
        }
        // bundle.js
        const script2 = document.createElement("script");
        let s2 = document.getElementById('bundle_script');
        script2.id = "bundle_script";
        script2.src = "/common/js/bundle.js";
        script2.async = true;
        if (s2 != null) {
            document.body.replaceChild(script2, s2);
        }
    }


    /**
     * 現在表示しているフォルダの親フォルダへ戻ります.
     *
     */
    backToUpperDirectory() {
        console.log('backToUpperDirectory/start');
        this.searchListData(this.state.current_parent_folder_id);
        console.log('backToUpperDirectory/end');
    }


    /**
     * 現在のフォルダーIDをもとにファイル一覧を再検索します.
     * ※このメソッドは子コンポーネントにて利用します
     *
     */
    _didFinish() {
        console.log('_didFinish/start');
        console.log('_didFinish/current_folder_id=' + this.state.current_folder_id)
        this.searchListData(this.state.current_folder_id);
        console.log('_didFinish/end');
    }


    /**
     * 指定したフォルダ直下のファイルおよびフォルダの一覧を取得します.
     *
     * @param {検索するフォルダID} argParamFolderId 
     */
    searchListData (argParamFolderId) {
        console.log('searchListData/start');
        console.log('searchListData/argParamFolderId=' + argParamFolderId);
        ApiClient.storageApi(
            ApiEnum.Code.FILE_GET_LIST, 
            {}, 
            HttpMethod.Code.GET,
            argParamFolderId)
            .then(json => {
                this.setState({
                    current_folder_root_flg: json['root_flg'],
                    current_folder_developer_flg: json['developer_folder_flg'],
                    current_folder_public_flg: json['public_folder_flg'],
                    current_folder_id: json['folder_id'],
                    current_folder_name: json['folder_name'],
                    current_parent_folder_id: json['parent_folder_id'],
                    ai_user_flg: json['ai_flg'],
                    message: '',
                    file_list: json['detail'],
                    breadcrumbs: json['breadcrumbs'],
                    selected_row_list: [],
                });

                // selected_row_listがリセットされたのでHTML側のチェックを外す
                document.getElementById('select_all').checked = false;
                json['detail'].forEach(function(rec, index) {
                    if (document.getElementById('row' + index)) {
                        document.getElementById('row' + index).checked = false;
                    }
                })
                // ボタン群も消す
                let elements = document.getElementsByClassName('select_list')[0];
                for (let child of elements.children) {
                    child.style["display"] = "none";
                }

                this.props.router.navigate('/app/filemanagement?folderid=' + json['folder_id'])
            }).catch(err => {
                this.props.router.navigate('/app/error', {
                    state: {code: err.errorCode, message: err.errorMessage}
                });
            }
        )
        console.log('searchListData/end');
    }


    /**
     * 表示中のファイル及びフォルダを名称の昇順に並び替えます.
     *
     */
    sortListData () {
        console.log('sortListData/start');
        if (!window.confirm(MessageUtil.getMessage('I02001'))) {
            return;
        }

        // ローディング追加
        Loading.addToElement('sort_button');

        ApiClient.storageApi(
            ApiEnum.Code.SORT_ORDER_LOGICAL_NAME_SORT, 
            {"folder_id" : this.state.current_folder_id}, 
            HttpMethod.Code.POST)
            .then(json => {
                // 並び替え後のファイル一覧を取得
                this.searchListData(this.state.current_folder_id);
                return;
            }).catch(err => {
                this.props.router.navigate('/app/error', {
                    state: {code: err.errorCode, message: err.errorMessage}
                });
            }).finally(() => {
                // ローディング削除
                Loading.removeFromElement('sort_button');
            });

        console.log('sortListData/end');
    }


    /**
     * 選択したファイルをお気に入りに登録します.
     *
     */
    resistFavorite () {
        console.log('resistFavorite/start')

        // ローディング追加
        Loading.addToElement('resist_favorite');

        let that = this;
        let executeCount = 0;
        this.state.selected_row_list.forEach(function(rec) {

            let param;
            if (rec['folder_folder_id'] == null) {
                // ファイルの場合
                console.log('file_id=' + rec['file_file_id']);
                param = {"file_id": rec['file_file_id']};
            } else {
                // フォルダの場合
                console.log('folder_id=' + rec['folder_folder_id']);
                param = {"folder_id": rec['folder_folder_id']};
            }

            ApiClient.storageApi(
                ApiEnum.Code.BOOKMARK_REGIST, 
                param, 
                HttpMethod.Code.POST)
                .then(json => {
                    executeCount++;

                    // 全てのオブジェクトを処理した場合は後処理を実施
                    if (executeCount === that.state.selected_row_list.length) {
                        // 一覧を再表示
                        that.searchListData(that.state.current_folder_id);
                        // 正常ダイアログを表示
                        window.alert(MessageUtil.getMessage('I00000'));
                    }
                }).catch(err => {
                    switch (err.errorCode) {
                        case 'E02024':
                        case 'E04001':
                                window.alert(err.errorMessage);
                            break;
                        default:
                            that.props.navigate('/app/error', {
                                state: {code: err.errorCode, message: err.errorMessage}
                            });
                            break;
                    }
                }).finally(() => {
                    // ローディング削除
                    Loading.removeFromElement('resist_favorite');
                });
        })

        console.log('resistFavorite/end');
    }


    /**
     * 選択済みの行をstateに保持します.
     *
     */
    checkSelectedRow () {
        console.log('checkSelectedRow/start');
        let tmpSelectedList = [];
        this.state.file_list.forEach(function(rec, index) {
            if (document.getElementById('row' + index).checked) {
                tmpSelectedList.push(rec);
            }
        })
        this.setState({selected_row_list: tmpSelectedList});
        console.log('checkSelectedRow/end');
    }

    /**
     * ファイルをD&Dまたはダイアログにて選択した場合にアップロードします.
     * アップロード予定のファイルを名称重複チェックする
     * 重複があった場合全ての項目でoverride_flg = trueとする
     * @param {*} filelist
     */
    uploadFile = (filelist, currentFolderId, csrfToken) => {
        console.log('uploadFile/start');

        const loading = document.getElementById('loading');
        if (loading != null) {
            return;
        }

        // ローディング追加
        Loading.addToElement('upload_area');

        // IE対策(フォルダ含んだD&Dの場合、ファイルリストが存在しない)
        if (filelist.length === 0) {
            setTimeout(() => {
                // ダイアログを表示
                window.alert(MessageUtil.getMessage('E02034'));
                //キャンセルならローディング消す
                Loading.removeFromElement('upload_area');
                // 設定されてしまったファイル情報をクリア
                document.getElementById("upload_files").value = '';
            }, 1000)
            return;
        }

        let promises = [];
        let checkResults = [];

        for (let i = 0; i < filelist.length; i++) {
            const fileName = filelist[i]['name'];
            promises[i] = ApiClient.storageApi(
                ApiEnum.Code.FILECHECK_NAME, {
                    "file_name": fileName,
                    "folder_id": currentFolderId,
                }, HttpMethod.Code.GET)
            .then(json => {
                checkResults[i] = json['check_rslt'];
            })
            .catch(err => {
                if (err.errorCode != null) {
                    this.props.router.navigate('/app/error', {
                        state: {code: err.errorCode, message: err.errorMessage}
                    });
                } else {
                    // 予期せぬ例外
                    this.props.router.navigate('/app/error');
                }
            });
        }

        const that = this;

        //重複チェック完了後に実行
        Promise.all( promises ).then( function ( message ) {
            //trueが存在する場合true
            const isDupulicate = checkResults.indexOf(true) >= 0;
            //重複がある場合
            if (isDupulicate) {
                //重複確認ダイアログ
                if (!window.confirm(MessageUtil.getMessage('I02003'))) {
                    //キャンセルならローディング消す
                    Loading.removeFromElement('upload_area');
                    // 設定されてしまったファイル情報をクリア
                    document.getElementById("upload_files").value = '';
                    return;
                }
            }
            // ファイルオブジェクトを順番に処理
            let executeCount = 0;
            // アップロードに失敗したファイルのメッセージ
            let failedUploadFileMessages = [];
            // アップロード処理後の後処理
            let postProcessing = (executeCount, filelistLength, failedUploadFileMessages) => {
                if (executeCount === filelistLength) {
                    // アップロードに失敗したファイルをダイアログに表示
                    that.showFailedUploadFileMessages(failedUploadFileMessages);
                }
            }

            for (let i = 0; i < filelist.length; i++) {

                let formData = new FormData();
                formData.append('file_name', filelist[i]['name']);
                formData.append('folder_id', currentFolderId);
                formData.append('upload_file', filelist[i]);
                formData.append('override_flg', isDupulicate);

                // サイズ0byteの場合はエラーメッセージをセットしてアップロードAPIは実行しない
                if (filelist[i].size === 0) {
                    executeCount++;
                    failedUploadFileMessages.push(`${filelist[i].name}: ${MessageUtil.getMessage('E02033')}`)
                    // ローディング削除
                    Loading.removeFromElement('upload_area');
                } else {
                    let request = new XMLHttpRequest();
                    request.open(HttpMethod.Code.POST, ApiEnum.Code.FILE_RESIST);
                    request.setRequestHeader("X-XSRF-TOKEN", csrfToken);
                    request.send(formData);
                    // eslint-disable-next-line no-loop-func
                    request.onloadend = function(e) {
                        //ファイルアップロード終了
                        executeCount++;
                        //正常
                        if (e.target.status === 200) {
                            // 全てのオブジェクトを処理した場合は後処理を実施
                            if (executeCount === filelist.length) {
                                const uploadFiles = document.getElementById("upload_files");
                                //nullならすでにエラー画面に遷移しているため何もしない
                                if (uploadFiles != null) {
                                    // 一覧を再表示
                                    that.searchListData(that.state.current_folder_id);
                                    uploadFiles.value = '';
                                    // ローディング削除
                                    Loading.removeFromElement('upload_area');
                                }
                            }
                        } else { //エラー
                            // ローディング削除
                            Loading.removeFromElement('upload_area');
                            if (e.target.status === 0) {
                                // フォルダーの中に1バイト以上のファイルがある場合
                                failedUploadFileMessages.push(`${filelist[i].name}: ${MessageUtil.getMessage('E02033')}`)
                            } else {
                                try {
                                    let json = JSON.parse(e.target.response);
                                    const errorCode = json['errorCode'];
                                    const errorMessage = json['errorMessage'];
                                    switch (errorCode) {
                                        case 'E00007': // バリデーション
                                        case 'E02029': // 使用不可文字
                                        case 'E02030': // サイズオーバー
                                        case 'E02032': // ファイル名の長さ
                                            failedUploadFileMessages.push(`${filelist[i].name}: ${errorMessage}`)
                                            break;
                                        default:
                                            that.props.navigate('/app/error', {
                                                state: {code: errorCode, message: errorMessage}
                                            });
                                            break;
                                    }
                                } catch (e) {
                                    // 予期せぬ例外
                                    that.props.navigate('/app/error');
                                }
                            }

                        }
                        postProcessing(executeCount, filelist.length, failedUploadFileMessages);
                    };
                }
                // アップロード対象が全て0バイトだった時用
                postProcessing(executeCount, filelist.length, failedUploadFileMessages);
            }
        });

        console.log('uploadFile/end');
    }


    /**
     * アップロードに失敗したファイルをダイアログに表示します。
     * @param {*} failedUploadFileMessages 
     */
    showFailedUploadFileMessages(failedUploadFileMessages) {
        if (failedUploadFileMessages.length !== 0) {
            let message = `以下のファイルアップロードに失敗しました。\r\n${failedUploadFileMessages.join("\r\n")}`;
            window.alert(message);
        }
    }

    /**
     * アップロードファイルの選択ダイアログを表示します.
     *
     */
    selectUploadFile () {
        console.log('selectUploadFile/start');
        // inputタイプ:fileのタグは外観を変えることが出来ないため、非表示としている。
        // その代わりにボタンを配置し、そのボタンを押下した場合に非表示となっているinput要素をclickしている。
        document.getElementById('upload_files').click();
        console.log('selectUploadFile/end');
    }


    /**
     * 選択済のファイル・フォルダに応じた名前変更画面を表示します.
     *
     */
    showRenameModal() {
        const selectedItem = this.state.selected_row_list[0]
        if (selectedItem.file_file_id != null) {
            // ファイルを選択していた場合
            document.getElementById('fileupdate').classList.add('is-show');
            this.child[1].initFileUpdateState(
                selectedItem.file_file_id,
                selectedItem.file_file_name
            )
        } else if (selectedItem.folder_folder_id != null) {
            // フォルダを選択していた場合
            this.child[0].initFolderUpdateState(
                selectedItem.folder_folder_id,
                selectedItem.folder_folder_name, 
                selectedItem.folder_explanatory_text,
                selectedItem.folder_private_flg
            );
            // モーダル表示
            document.getElementById('updatefolder').classList.add('is-show');
        }
    }


    /**
     * ファイル移動画面の初期化を呼び出します.
     *
     */
    showMoveModal() {
        document.getElementById('move').classList.add('is-show');
        this.child[2].initMoveState(
            this.state.current_folder_id,
            this.state.selected_row_list,
        )
    }

    /**
     * おまとめ画面へ戻る.
     *
     */
    moveTopPage() {
        console.log('moveTopPage/start');
        let link = document.createElement('a');
        link.href = process.env.REACT_APP_OMATOME;
        ApiClient.storageApi(
            ApiEnum.Code.SESSION_DELETE,
            {},
            HttpMethod.Code.GET)
            .then(
                link.click()
            )
            .catch(err => {
                this.props.router.navigate('/app/error', {
                    state: { code: err.errorCode, message: err.errorMessage }
                });
            });
        console.log('moveTopPage/end');
    }

    /**
     * チェックで選択されたファイルをダイアログで選択されたファイルに差し替える
     * アップロード予定のファイルを名称重複チェックする
     * 重複があった場合override_flg = trueとする
     * 
     */
    replaceFile(filelist, currentFolderId, csrfToken) {
        console.log('replaceFile/start');
        const uploadFile = filelist[0];

        // ローディング追加
        Loading.addToElement('replace_data_lavel');

        // 差し替え対象のファイルID取得
        const selectedFileId = this.state.selected_row_list[0].file_file_id;
        // フォルダを選択している場合はエラーを表示
        if (selectedFileId == null) {
            window.alert(MessageUtil.getMessage('E02038'));
            // ローディング削除
            Loading.removeFromElement('replace_data_lavel');
            // 一覧を再表示
            this.searchListData(this.state.current_folder_id);
            return;
        }

        // 差し替え後のファイルのサイズが0byteの場合はエラー
        if (uploadFile.size === 0) {
            window.alert(MessageUtil.getMessage('E02033'));
            // ローディング削除
            Loading.removeFromElement('replace_data_lavel');
            // 一覧を再表示
            this.searchListData(this.state.current_folder_id);
            return;
        }

        let isDupulicate = false;

        // 名前重複チェック実行
        ApiClient.storageApi(
            ApiEnum.Code.FILECHECK_NAME, {
                "file_name": uploadFile['name'],
                "folder_id": currentFolderId,
            }, HttpMethod.Code.GET)
        .then(json => {
            isDupulicate = json['check_rslt'];

            //重複している場合
            if (isDupulicate) {
                //重複確認ダイアログ
                if (!window.confirm(MessageUtil.getMessage('I02003'))) {
                    //キャンセルならローディング削除
                    Loading.removeFromElement('replace_data_lavel');
                    // 設定されたファイル情報をクリア
                    document.getElementById("replace_data").value = '';
                    return;
                }
            }

            // ファイル差し替えAPIリクエスト作成
            let formData = new FormData();
            formData.append('file_id', selectedFileId);
            formData.append('file_name', uploadFile['name']);
            formData.append('upload_file', uploadFile);
            formData.append('override_flg', isDupulicate);

            // ファイル差し替えAPI実行
            let request = new XMLHttpRequest();
            request.open(HttpMethod.Code.POST, ApiEnum.Code.FILE_REPLACE);
            request.setRequestHeader("X-XSRF-TOKEN", csrfToken);
            request.send(formData);
            const that = this;
            // eslint-disable-next-line no-loop-func
            request.onloadend = function(e) {
                if (e.target.status !== 200) {
                    // ローディング削除
                    Loading.removeFromElement('replace_data_lavel');
                    try {
                        let json = JSON.parse(e.target.response);
                        const errorCode = json['errorCode'];
                        const errorMessage = json['errorMessage'];
                        switch (errorCode) {
                            case 'E00007': // バリデーション
                            case 'E02012': // 差し替え前ファイルの削除後ファイル名の長さ
                            case 'E02022': // ファイル重複
                            case 'E02029': // 使用不可文字
                            case 'E02030': // サイズオーバー
                            case 'E02032': // 差し替え後ファイル名の長さ
                            case 'E02038': // その他エラー
                                window.alert(errorMessage);
                                break;
                            default:
                                that.props.navigate('/app/error', {
                                    state: {code: errorCode, message: errorMessage}
                                });
                            break;
                        }
                    } catch (e) {
                        // 予期せぬ例外
                        that.props.navigate('/app/error');
                    }
                }
                // 一覧を再表示
                that.searchListData(that.state.current_folder_id);
            }
        })
        .catch(err => {
            if (err.errorCode != null) {
                this.props.router.navigate('/app/error', {
                    state: {code: err.errorCode, message: err.errorMessage}
                });
            } else {
                // 予期せぬ例外
                this.props.router.navigate('/app/error');
            }
        });

        console.log('replaceFile/end');
    }

    /**
     * チェックで選択されたフォルダ/ファイル情報をcsvでダウンロードします。
     * 
     */
    outputFileInfoCsv() {
        console.log('executeExport/start');
        
        // ローディング追加
        Loading.addToElement('execute_export_csv');

        let tmpDownloadTarget = [];
        this.state.selected_row_list.forEach(function(rec) {
            if (rec['folder_folder_id'] == null) {
                // ファイルの場合
                tmpDownloadTarget.push({
                    id: rec['file_file_id'],
                    file_flg: true,
                });
            } else {
                // フォルダの場合
                tmpDownloadTarget.push({
                    id: rec['folder_folder_id'],
                    file_flg: false,
                });
            }
        })

        let tmpUrlParam = '?folder_id=' + this.state.current_folder_id + '&folder_name=' + this.state.current_folder_name + '&';
        tmpDownloadTarget.forEach(function(rec, index) {
            tmpUrlParam
                += 'targets[' + index + '].id=' + rec.id + '&targets[' + index + '].file_flg=' + rec.file_flg + '&'
        })

        ApiClient.storageApiBlob(
            ApiEnum.Code.FILE_INFO_EXPORT + encodeURI(tmpUrlParam),
            {},
            HttpMethod.Code.GET)
            .then(retVal => {
                let blob = retVal[0];
                let headers = retVal[1];

                // レスポンスヘッダーからContent-Dispositionの値を取得
                var contentDisposition = headers.get('content-disposition');
	            // ファイル名を抽出
                var searchStr = "attachment; filename*=utf-8''";
                var index = contentDisposition.indexOf(searchStr);
                let fileName = decodeURI(contentDisposition.slice(index + searchStr.length));

                // BLOBファイルをダウンロード
                let link = document.createElement('a');
                if (window.navigator.msSaveBlob) {
                    // for IE
                    window.navigator.msSaveBlob(blob, fileName);
                } else {
                    // for Chrome
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                }

                // 一覧を再表示
                this.searchListData(this.state.current_folder_id);

            }).catch(err => {
                if (err.errorCode != null) {
                    this.props.router.navigate('/app/error', {
                        state: {code: err.errorCode, message: err.errorMessage}
                    });
                } else {
                    // 予期せぬ例外
                    this.props.router.navigate('/app/error');
                }
            }).finally(() => {
                // ローディング削除
                Loading.removeFromElement('execute_export_csv');
            });
        console.log('executeExport/end');
    }

    /**
     * 画面描画処理.
     *
     */
    render () {
        return (
            <>
                <header className="header">
                    {/* rootフォルダの場合は戻るボタンを非表示 */}
                    {this.state.current_folder_root_flg === false && (
                        <button className="button" onClick={(e) => this.backToUpperDirectory()}><span>戻る</span></button>
                    )}
                    <div className="pagename icon-folder icon-white">{this.state.current_folder_name}</div>
                    <span>
                        <button className="button button-search js-modal" data-id="search"><span>検索</span></button>
                        <button className="button" onClick={(e) => this.moveTopPage()}>トップメニュー 一覧</button>
                    </span>
                </header>
                <DndProvider backend={touchable() ? TouchBackend : HTML5Backend}>
                    <main className="main">
                        <ol className="breadcrumbs">
                          {/* rootフォルダの場合はパンくずリストを非表示 */}
                          {this.state.current_folder_root_flg === false && this.state.breadcrumbs.map((breadcrumb, i) => (
                            <li>
                              <a
                                className={(this.state.breadcrumbs.length - 1 !== i) ? "breadcrumb" : "last_breadcrumb"}
                                href={'/app/filemanagement?folderid=' + breadcrumb.folderId}
                              >
                                {breadcrumb.folderName}
                              </a>
                            </li>
                          ))}
                        </ol>
                        <span className="label">{this.state.message}</span>
                        {/* developフォルダの場合は新しいフォルダをつくるボタンを非表示 */}
                        {this.state.current_folder_developer_flg === false && (
                            <div className="intro">
                                <p>ファイルをアップロードしたり、並び替えができます。</p>
                                <button className="button button-newfolder js-modal" data-id="newfolder">新しいフォルダをつくる</button>
                            </div>
                        )}

                        {/* developフォルダの場合は新しいファイルをアップロードするオブジェクトを非表示 */}
                        {this.state.current_folder_developer_flg === false && (
                            <FileDropBox 
                                current_folder_id={this.state.current_folder_id} 
                                uploadFile={this.uploadFile}
                                selectUploadFile={this.selectUploadFile}
                                token={this.state.token}
                            />
                        )}

                            <div className="select_menu">
                                <div className="select_all">
                                    <input type="checkbox" id="select_all" onChange={(e) => this.checkSelectedRow()} />
                                    <label htmlFor="select_all">全て選択</label>
                                </div>
                                <div className="select_list">
                                    <span>チェックした項目の</span>
                                    {/* developフォルダの場合は移動ボタンを非表示 */}
                                    {this.state.current_folder_developer_flg === false && (
                                        <button className="button js-modal" data-tying="folder_operation" data-checklen="1" data-id="move" onClick={(e) => this.showMoveModal()}>移動</button>
                                    )}
                                    {/* developフォルダの場合は名前変更ボタンを非表示 */}
                                    {this.state.current_folder_developer_flg === false && (
                                        <button className="button js-modal" data-tying="folder_operation" data-checklen="1" data-checkmax="1" onClick={(e) => this.showRenameModal()}>名前変更</button>
                                    )}
                                    {/* developフォルダの場合はダウンロードボタンを非表示 */}
                                    {this.state.current_folder_developer_flg === false && (
                                        <button className="button js-modal" data-tying="folder_operation" data-id="storagedownload" data-checklen="1">ダウンロード</button>
                                    )}
                                    <button id="resist_favorite" className="button" data-tying="folder_operation" data-checklen="1" onClick={(e) => this.resistFavorite()}>よく使う書類入れへ</button>
                                    {/* developフォルダの場合はファイルID出力ボタンを非表示 */}
                                    {(this.state.current_folder_developer_flg === false && this.state.ai_user_flg) && (
                                        <button id='execute_export_csv' className="button" data-tying="folder_operation" data-id="storageoutputcsv" data-checklen="1" onClick={(e) => this.outputFileInfoCsv()}>ファイルID出力</button>
                                    )}
                                    {/* developフォルダの場合はデータ差し替えボタンを非表示 */}
                                    {(this.state.current_folder_developer_flg === false && this.state.selected_row_list.length === 1 && typeof this.state.selected_row_list[0].file_file_id === 'string') && (
                                        <>
                                            <label id="replace_data_lavel" htmlFor="replace_data" className="button" data-tying="folder_operation" data-checklen="1" data-checkmax="1">データ差し替え</label>
                                            <input type="file" id="replace_data" onChange={(e) => this.replaceFile(e.target.files, this.state.current_folder_id, this.state.token)} />
                                        </>
                                    )}
                                    {/* developフォルダの場合は削除ボタンを非表示 */}
                                    {this.state.current_folder_developer_flg === false && (
                                        <button className="button button-delete js-modal" data-tying="folder_operation" data-id="filedelete" data-checklen="1"><span>削除</span></button>
                                    )}
                                </div>
                            </div>

                        <div className="table">
                            {this.renderTable()}
                            <div className="table_aside table_aside-right">
                                {/* デベロッパーフォルダではない場合並び替え表示 */}
                                {this.state.current_folder_developer_flg === false && (
                                    <button id="sort_button" className="button" onClick={(e) => this.sortListData()}>0→9順に並び替え</button>
                                )}
                            </div>
                            <div>
                                <img src= "/common/image/icon/prohibited_characters.png" border="1" className="prohibited"/>
                            </div>
                        </div>

                    </main>
                </DndProvider>

                {/* 検索ウィンドウ */}
                <FileSearchForm
                    current_folder_id={this.state.current_folder_id}
                    current_folder_name={this.state.current_folder_name}
                    is_search_result={false}
                />
                {/* 新しいフォルダを作るウィンドウ */}
                <FolderCreateForm 
                    reloadParentForm={this._didFinish.bind(this)} 
                    folderId={this.state.current_folder_id}
                />
                {/* フォルダ名更新ウィンドウ */}
                <FolderUpdateForm 
                    reloadParentForm={this._didFinish.bind(this)} 
                    folderId={this.state.current_folder_id}
                    onRef={ref => (this.child[0] = ref)}
                />
                {/* ファイル・フォルダ移動ウィンドウ */}
                <FileMoveForm
                    current_folder_id={this.state.current_folder_id}
                    reloadParentForm={this._didFinish.bind(this)}
                    onRef={ref => (this.child[2] = ref)}
                />
                {/* 名前変更ウィンドウ */}
                <FileUpdateForm
                    reloadParentForm={this._didFinish.bind(this)}
                    folderId={this.state.current_folder_id}
                    onRef={ref => (this.child[1] = ref)}
                />
                {/* ダウンロードウィンドウ */}
                <FileDownloadForm key="FileDownloadForm"
                    reloadParentForm={this._didFinish.bind(this)}
                    selectedList={this.state.selected_row_list}
                />
                {/* ファイル削除ウィンドウ */}
                <FileDeleteForm
                    reloadParentForm={this._didFinish.bind(this)}
                    selectedList={this.state.selected_row_list}
                />

            </>
        );
    }


    /**
     * 画面描画処理（テーブル部分）.
     *
     */
    renderTable() {
        const { file_list } = this.state;
        return(
            <>
                {(touchable() || isIE) && (
                    <FileManagementDragLayer data={file_list} />
                )}
                <table data-alltarget>
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th>名前</th>
                            <th>更新日時</th>
                            <th>並び替え</th>
                        </tr>
                    </thead>
                    <tbody>
                        {file_list.map((data, i) => (
                            <FileManagerRow
                                key={i}
                                index={i}
                                reorder={this.reorder}
                                data={data}
                                current_folder_id={this.state.current_folder_id}
                                draggableComponent={this.draggableComponent}
                                checkSelectedRow={this.checkSelectedRow}
                                historyPush={this.historyPush}
                                isDeveloper={this.state.current_folder_developer_flg}
                                isIE={isIE}
                                isAiUser={this.state.ai_user_flg}
                            />
                        ))}
                    </tbody> 
                </table>
            </>
        ) 
        
    }


}

export default withRouter(FileManagementForm);
