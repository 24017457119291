class StringUtil {

	/**
	 * バイト表記のファイルサイズを変換します.（バイト／KB／MB／GB）
     *
	 * @param {単位の取得元となるファイルサイズ} argFileSize
	 */
	convertFileSize (argFileSize) {
        let tmpSize = argFileSize;
        let sizes =['バイト', 'KB', 'MB', 'GB'];
        let ext = sizes[0];
        for (let i = 1; i < sizes.length; i++) {
            if (1024 <= tmpSize) {
                tmpSize = tmpSize / 1024;
                ext = sizes[i];
            }
        }

        let resultFileSize;
        switch (ext) {
            case sizes[0]:
                // バイトの場合
                // 編集を行わない
                resultFileSize = tmpSize + ext;
                break;
            case sizes[1]:
            case sizes[2]:
                // KBまたはMBの場合
                // 小数点切り捨て
                resultFileSize = Math.floor(tmpSize) + ext;
                break;
            case sizes[3]:
                // GBの場合
                // 小数点第二位切り捨て
                resultFileSize = (Math.floor(tmpSize * 10) / 10) + ext;
                break;
            default:
                break;
        }

        return resultFileSize;
    }

    /**
     * 日付をフォーマットする
     * @param  {Date}   date     日付
     * @param  {String} [format] フォーマット
     * @return {String}          フォーマット済み日付
     */
    formatDate = function (date, format) {
        if (!format) format = 'YYYY-MM-DD hh:mm:ss.SSS';
        format = format.replace(/YYYY/g, date.getFullYear());
        format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
        format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
        format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
        format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
        format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
        if (format.match(/S/g)) {
            let milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
            let length = format.match(/S/g).length;
            for (let i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
        }
        return format;
    };

}

export default new StringUtil();
