class ApiEnum {

    Code = {
        STORAGEACCESS: process.env.REACT_APP_STORAGEACCESS,
        FILE_RESIST: process.env.REACT_APP_FILE_RESIST,
        FILE_GET_LIST: process.env.REACT_APP_FILE_GET_LIST,
        FILE_SEARCH: process.env.REACT_APP_FILE_SEARCH,
        FILE_FULL_TEXT_SEARCH: process.env.REACT_APP_FILE_FULL_TEXT_SEARCH,
        FILE_UPDATE: process.env.REACT_APP_FILE_UPDATE,
        FILE_REPLACE: process.env.REACT_APP_FILE_REPLACE,
        FILE_DELETE: process.env.REACT_APP_FILE_DELETE,
        FILE_VIEWER_BOOKMARK_PAGING: process.env.REACT_APP_FILE_VIEWER_BOOKMARK_PAGING,
        FILE_VIEWER_FILELIST_PAGING: process.env.REACT_APP_FILE_VIEWER_FILELIST_PAGING,
        FILE_EXTENSION_CHECK: process.env.REACT_APP_FILE_EXTENSION_CHECK,
        FILE_VIEW: process.env.REACT_APP_FILE_VIEW,
        FILE_MOVE: process.env.REACT_APP_FILE_MOVE,
        FILE_DOWNLOAD: process.env.REACT_APP_FILE_DOWNLOAD,
        FILECHECK_NAME: process.env.REACT_APP_FILECHECK_NAME,
        FILE_GET_PRESIGNED_URL: process.env.REACT_APP_FILE_GET_PRESIGNED_URL,
        FILE_INFO_EXPORT: process.env.REACT_APP_FILE_INFO_EXPORT,
        FOLDER_UPDATE: process.env.REACT_APP_FOLDER_UPDATE,
        FOLDER_GET_DEST_LIST: process.env.REACT_APP_FOLDER_GET_DEST_LIST,
        SORT_ORDER_RESIST: process.env.REACT_APP_SORT_ORDER_RESIST,
        SORT_ORDER_UPDATE: process.env.REACT_APP_SORT_ORDER_UPDATE,
        SORT_ORDER_DELETE: process.env.REACT_APP_SORT_ORDER_DELETE,
        SORT_ORDER_LOGICAL_NAME_SORT: process.env.REACT_APP_SORT_ORDER_LOGICAL_NAME_SORT,
        BOOKMARK_DELETE: process.env.REACT_APP_BOOKMARK_DELETE,
        BOOKMARK_REGIST: process.env.REACT_APP_BOOKMARK_REGIST,
        BOOKMARK_GET: process.env.REACT_APP_BOOKMARK_GET,
        FOLDER_CREATE: process.env.REACT_APP_FOLDER_CREATE,
        FOLDERNAME_CHECK: process.env.REACT_APP_FOLDERNAME_CHECK,
        GET_USER_INFO: process.env.REACT_APP_GET_USER_INFO,
        SESSION_DELETE: process.env.REACT_APP_SESSION_DELETE,
    };
}

export default new ApiEnum();