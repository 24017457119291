import React from 'react';
import {withRouter} from '../../libs/withRouter';
import {ApiEnum, ApiClient, HttpMethod} from '../../api/Api';
import Loading from '../../libs/Loading';
import MessageUtil from '../../libs/MessageUtil';

/********************************************************************
 *
 * STORAGE-005 フォルダ更新
 *
 *******************************************************************/
class FolderUpdateForm extends React.Component {

    /**
     * コンストラクタ.
     *  
     * 初期化処理を行います.
     *
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        
        this.state = {
            folder_id: null,
            folder_name: '',
            folder_desc: '',
            public_flg: true,
            message: ''
        }
    }

    componentDidMount() {
        this.props.onRef(this)
    }
    componentWillUnmount() {
        this.props.onRef(null)
    }

    /**
     * State初期化.
     *
     * stateの初期化を行います.
     *
     */
    initFolderUpdateState(folderId, folderName, folderDesc, privateFlg) {
        this.setState({ 
            folder_id: folderId,
            folder_name: folderName == null ? "" : folderName,
            folder_desc: folderDesc == null ? "" : folderDesc,
            public_flg: privateFlg == null ? true : !privateFlg
        });
    }
    


    /**
   　 * 入力値更新
     */
    changeInputValue (e) {
        switch (e.target.id) {
            case "privateswitch":
                this.setState({ public_flg: !this.state.public_flg })
                break;
            case "foldername":
                this.setState({ folder_name: e.target.value })
                break;
            case "folderdescription":
                this.setState({ folder_desc: e.target.value })
                break;
            default:
                break;
        }
    }

    /**
     * モーダル閉じる
     */
    closeModal() {
        // 自画面を閉じる
        document.getElementById('updatefolder').classList.remove('is-show');
        //閉じるときにリセット
        this.initFolderUpdateState();
    }

    /**
     * 更新ボタン押下時の処理
     * @param {イベント} evt 
     */
    onUpdateButton(evt) {
        //重複チェック
        const currentFolderId = this.props.folderId;
        const folderName = this.state.folder_name;
        const folderId = this.state.folder_id;
        ApiClient.storageApi(ApiEnum.Code.FOLDERNAME_CHECK, {
            "folder_id": folderId,
            "folder_name": folderName,
            "parent_folder_id": currentFolderId,
        }, HttpMethod.Code.GET)
        .then(json => {
            const isDuplicate = json['check_rslt'];
            if (isDuplicate) { //重複している場合メッセージダイアログ
                if (!window.confirm(MessageUtil.getMessage('I02002'))) {
                    //キャンセル（何もしない）
                } else {
                    //OK押下の場合、重複状態でフォルダ作成
                    this.executeUpdate(true)
                }
            } else { 
                //フォルダ作成
                this.executeUpdate(false)
            }
        }).catch(err => {
            this.props.router.navigate('/app/error', {
                state: {code: err.errorCode, message: err.errorMessage}
            });
        });
    }

    /**
     * 指定されたフォルダの情報を更新します.
     * @param {重複許可} isDuplicate 
     */
    executeUpdate (isDuplicate) {
        console.log('executeUpdate/start');

        //ローディング追加
        Loading.addToElement('update_button');

        ApiClient.storageApi(
            ApiEnum.Code.FOLDER_UPDATE, 
            {
                "folder_id": this.state.folder_id,
                "folder_name": this.state.folder_name,
                "private_flg": !this.state.public_flg,
                "explanatory_text": this.state.folder_desc,
                "override_flg": isDuplicate
            },
            HttpMethod.Code.PUT)
            .then(json => {
                // 自画面を閉じる
                this.closeModal();
                // 親画面を再描画
                this.props.reloadParentForm();
            }).catch(err => {
                switch (err.errorCode) {
                    case 'E00007':
                    case 'E02029': //使用不可文字
                    case 'E03016': //フォルダ名必須
                    case 'E03017': //フォルダ名桁数
                    case 'E03022': //フォルダ名S3オブジェクトキー長チェック
                    case 'E03019': //フォルダ名型チェック/制限文字チェック
                    case 'E03018': //説明文桁数チェック
                        window.alert(err.errorMessage);
                        break;
                    default:
                        this.props.router.navigate('/app/error', {
                            state: {code: err.errorCode, message: err.errorMessage}
                        });
                        break;
                }
            }).finally(() => {
                // ローディング削除
                Loading.removeFromElement('update_button');
            });
        console.log('executeUpdate/end');
    }


    /**
     * 画面描画処理.
     *
     * HTMLを生成します.
     */
    render () {
        return (
            <div id="updatefolder" className="modal">
                <div className="modal_content">
                    <p className="title">フォルダ更新</p>
                    <div className="inputfield">
                        <label className="form-switch right">
                            <span className="full">お客さま画面へ表示</span>
                            <span>しない<br />(下書き保存)</span>
                            <input id="privateswitch" type="checkbox" checked={this.state.public_flg} onChange={(e) => this.changeInputValue(e)}/>
                            <i></i>
                            <span>する</span>
                        </label>
                        <span>
                            <label>フォルダ名</label>
                            <input id="foldername" type="text" placeholder="" maxLength="50"
                                value={this.state.folder_name} 
                                onChange={(e) => this.changeInputValue(e)}/>
                                <p className="count__text">推奨文字数残り<span id="textlength2">
                                    { 40 - this.state.folder_name.length }</span>文字</p>
                        </span>
                        <span>
                            <label>説明</label>
                            <input id="folderdescription" type="text" placeholder="" maxLength="150" value={this.state.folder_desc} onChange={(e) => this.changeInputValue(e)}/>
                            <p className="count__text">推奨文字数残り<span id="textlength3">
                                    { 40 - this.state.folder_desc.length }</span>文字</p>
                        </span>
                        <div className="inputfield_unit">
                            <button id="update_button" className="button drag" onClick={
                                (e) => {this.onUpdateButton(e)}
                            }>更新</button>
                        </div>
                    </div>
                    <div className="button button-close" onClick={(e) => this.closeModal()}></div>
                </div>
            </div>
        )
    }


}

export default withRouter(FolderUpdateForm);
