import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.jsx';
import * as serviceWorker from './serviceWorker';

// npm start以外（＝開発モード以外）で起動した場合はconsole.log()を何もしないメソッドにすり替える
if (process.env.NODE_ENV !== "development") {
    console.log = () => {};
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

serviceWorker.unregister();
