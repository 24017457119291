import React from 'react';
import {withRouter} from '../../libs/withRouter';
import {ApiEnum, ApiClient, HttpMethod} from '../../api/Api';
import Loading from '../../libs/Loading';

/********************************************************************
 *
 * STORAGE-008 ファイル・フォルダ削除
 *
 *******************************************************************/
class FileDeleteForm extends React.Component {

    /**
     * 指定されたファイルまたはフォルダを削除します.
     *
     */
    executeDelete () {
        console.log('executeDelete/start');

        // ローディング追加
        Loading.addToElement('execute_delete');

        let tmpDeleteTarget = [];
        this.props.selectedList.forEach(function(rec) {
            if (rec['folder_folder_id'] == null) {
                // ファイルの場合
                tmpDeleteTarget.push({
                    "id": rec['file_file_id'],
                    "file_flg": true,
                });
            } else {
                // フォルダの場合
                tmpDeleteTarget.push({
                    "id": rec['folder_folder_id'],
                    "file_flg": false,
                });
            }
        })

        let tmpUrlParam = '?';
        tmpDeleteTarget.forEach(function(rec, index) {
            tmpUrlParam
                += 'targets[' + index + '].id=' + rec.id + '&targets[' + index + '].file_flg=' + rec.file_flg + '&'
        })

        ApiClient.storageApi(
            ApiEnum.Code.FILE_DELETE + encodeURI(tmpUrlParam),
            {
                "targets": tmpDeleteTarget,
            },
            HttpMethod.Code.DELETE)
            .then(json => {
                // 自画面を閉じる
                document.getElementById('filedelete').classList.remove('is-show');
                // 親画面を再描画
                this.props.reloadParentForm();
            }).catch(err => {
                switch (err.errorCode) {
                    case 'E00007':
                        window.alert(err.errorMessage);
                        break;
                    default:
                        this.props.router.navigate('/app/error', {
                            state: {code: err.errorCode, message: err.errorMessage}
                        });
                        break;
                }
            }).finally(() => {
                // ローディング削除
                Loading.removeFromElement('execute_delete');
            });

        console.log('executeDelete/end');
    }


    /**
     * 画面描画処理.
     *
     */
    render () {
        return (
            <div id="filedelete" className="modal">
                <div className="modal_content">
                    <p className="title">ファイル・フォルダ削除</p>
                    <div className="actionfield">
                        <div className="actionfield_confirm">
                            <p className="actionfield_head">削除対象</p>
                            <p className="actionfield_body">{
                                this.props.selectedList.map((record, index) =>
                                    <FileDeleteRow 
                                        key={'filedelete_' + index}
                                        delFileRec={record}
                                        index={index}
                                    />
                                )
                            }</p>
                        </div>
                        <div className="actionfield_unit">
                            <button id="execute_delete" className="button button-delete drag" onClick={(e) => this.executeDelete()}><span>これを削除</span></button>
                        </div>
                    </div>
                    <div className="button button-close" />
                </div>
            </div>
        )
    }

}

/**
 * 削除するファイルの表示用要素
 * @param {プロパティ} props 
 */
const FileDeleteRow = props => {
    const record = props.delFileRec
    return record['folder_folder_id'] == null ? 
        <span key={record['file_file_name']} className="actionfield_row icon-file">{record['file_file_name']}</span>
        :
        <span key={record['folder_folder_name']} className="actionfield_row icon-folder">{record['folder_folder_name']}</span>
}

export default withRouter(FileDeleteForm);
