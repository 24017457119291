import React from 'react'
import { NativeTypes } from 'react-dnd-html5-backend'
import { useDrop } from 'react-dnd'

/**
 * ファイルドロップ用要素
 * @param {プロパティ} props 
 */
const DropFileBox = props => {
    const { onDrop } = props

    const [dropProps, drop] = useDrop({
        accept: [NativeTypes.FILE],
        drop(item, monitor) {
            //ドロップされた
            props.uploadFile(item.files, props.current_folder_id, props.token)
            if (onDrop && dropProps !== undefined) {
                onDrop(props, monitor)
            }
        }, collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    })

    return (
        <div ref={drop}>
            <div id="upload_area" className="drag">
                <p>新しくファイルをアップロード（ここにドラッグ）</p>
                <input
                    type="file"
                    id="upload_files"
                    name="files[]"
                    onChange={(e) => props.uploadFile(e.target.files, props.current_folder_id, props.token)}
                />
                <label htmlFor="main_file" className="button" onClick={(e) => props.selectUploadFile()}>ファイルを選択</label>
            </div>
        </div>
    )
}
export default DropFileBox