import React from 'react';
import {withRouter} from '../../libs/withRouter';
import {ApiEnum, ApiClient, HttpMethod} from '../../api/Api';
import MessageUtil from '../../libs/MessageUtil';
import StringUtil from '../../libs/StringUtil';
import Loading from '../../libs/Loading';
import FileDownloadForm from "../file_download/FileDownloadForm.jsx";

/********************************************************************
 *
 * STORAGE-006 お気に入り一覧
 *
 *******************************************************************/
class BookmarkForm extends React.Component {

    /**
     * 初期化処理を行います.
     *
     * @param {*} props 
     */
    constructor (props) {
        super(props);

        document.title = 'よく使う書類入れ｜ストレージ';

        this.state = {
            bookmark_list: [],        // お気に入り一覧
            selected_row_list: [],    // 選択した行一覧
            ai_user_flg: '',          // 新AI管理員サービスの権限チェック
            show_tooltip_list: []        // ツールチップを表示するファイルID一覧
        }
    }

    /**
     * コンポーネントがマウントされた場合に実行される処理です.
     *
     */
    componentDidMount () {
        console.log('componentDidMount/start');
        // お気に入り一覧を取得
        this.getBookmarkList();

        console.log('componentDidMount/end');
    }


    componentDidUpdate () {
        //動的に生成される要素のためにjsを再読込する
        // check.js
        const script1 = document.createElement("script");
        let s1 = document.getElementById('check_script');
        script1.id = "check_script";
        script1.src = "/common/js/check.js";
        script1.async = true;
        if (s1 != null) {
            document.body.replaceChild(script1, s1);
        }
        // bundle.js
        const script2 = document.createElement("script");
        let s2 = document.getElementById('bundle_script');
        script2.id = "bundle_script";
        script2.src = "/common/js/bundle.js";
        script2.async = true;
        if (s2 != null) {
            document.body.replaceChild(script2, s2);
        }
    }


    /**
     * 現在のフォルダーIDをもとにファイル一覧を再検索します.
     * ※このメソッドは子コンポーネントにて利用します
     *
     */
    _didFinish() {
        console.log('_didFinish/start');
        this.getBookmarkList();
        console.log('_didFinish/end');
    }


    /**
     * 指定したユーザのお気に入り一覧を取得します.
     *
     * @param {検索するユーザID} argUserId
     */
    getBookmarkList () {
        console.log('searchBookmarkList/start');
        ApiClient.storageApi(
            ApiEnum.Code.BOOKMARK_GET, 
            {}, 
            HttpMethod.Code.GET)
            .then(json => {
                this.setState({
                    bookmark_list: json['bookmark_list'],
                    ai_user_flg: json['ai_flg']
                });

                // bookmark_listがリセットされたのでHTML側のチェックを外す
                document.getElementById('select_all').checked = false;
                json['bookmark_list'].forEach(function(rec, index) {
                    if (document.getElementById('row' + index)) {
                        document.getElementById('row' + index).checked = false;
                    }
                })
                // ボタン群も消す
                let elements = document.getElementsByClassName('select_list')[0];
                for (let child of elements.children) {
                    child.style["display"] = "none";
                }

            }).catch(err => {
                this.props.router.navigate('/app/error', {
                    state: {code: err.errorCode, message: err.errorMessage}
                });
            }
        )
        console.log('searchBookmarkList/end');
    }


    /**
     * 選択済みの行をstateに保持します.
     *
     */
    checkSelectedRow () {
        console.log('checkSelectedRow/start');
        let tmpSelectedList = [];
        this.state.bookmark_list.forEach(function(rec, index) {
            if (document.getElementById('row' + index).checked) {
                tmpSelectedList.push(rec);
            }
        })
        this.setState({selected_row_list: tmpSelectedList});
        console.log('checkSelectedRow/end');
    }


    /**
     * 選択したファイル・フォルダをお気に入りから削除します.
     *
     */
    deleteBookmark () {
        console.log('deleteBookmark/start');

        // ローディング追加
        Loading.addToElement('execute_delete');

        let executeCount = 0;
        let that = this;
        this.state.selected_row_list.forEach(function(rec) {

            ApiClient.storageApi(
                ApiEnum.Code.BOOKMARK_DELETE, 
                {"bookmark_id": rec['bookmark_id']}, 
                HttpMethod.Code.DELETE)
                .then(json => {
                    executeCount++;

                    // 全てのオブジェクトを処理した場合は後処理を実施
                    if (executeCount === that.state.selected_row_list.length) {
                        // 削除後のお気に入り一覧を取得
                        this.getBookmarkList();
                        // 正常ダイアログを表示
                        window.alert(MessageUtil.getMessage('I00000'));
                    }
                }).catch(err => {
                    switch (err.errorCode) {
                        case 'E00007':
                            window.alert(err.errorMessage);
                            break;
                        default:
                            this.props.router.navigate('/app/error', {
                                state: {code: err.errorCode, message: err.errorMessage}
                            });
                            break;
                    }
                }).finally(() => {
                    // ローディング削除
                    Loading.removeFromElement('execute_delete');
                });
        }, this)

        console.log('deleteBookmark/end');
    }

    /**
     * チェックで選択されたフォルダ/ファイル情報をcsvでダウンロードします。
     * 
     */
    outputFileInfoCsv() {
        console.log('executeExport/start');

        // ローディング追加
        Loading.addToElement('execute_export_csv');

        let tmpDownloadTarget = [];
        this.state.selected_row_list.forEach(function(rec) {
            if (rec['folder_folder_id'] == null) {
                // ファイルの場合
                tmpDownloadTarget.push({
                    id: rec['file_file_id'],
                    file_flg: true,
                });
            } else {
                // フォルダの場合
                tmpDownloadTarget.push({
                    id: rec['folder_folder_id'],
                    file_flg: false,
                });
            }
        })

        let tmpUrlParam = '?';
        tmpDownloadTarget.forEach(function(rec, index) {
            tmpUrlParam
                += 'targets[' + index + '].id=' + rec.id + '&targets[' + index + '].file_flg=' + rec.file_flg + '&'
        })

        ApiClient.storageApiBlob(
            ApiEnum.Code.FILE_INFO_EXPORT + encodeURI(tmpUrlParam),
            {},
            HttpMethod.Code.GET)
            .then(retVal => {
                let blob = retVal[0];
                let headers = retVal[1];

                // レスポンスヘッダーからContent-Dispositionの値を取得
                var contentDisposition = headers.get('content-disposition');
	            // ファイル名を抽出
                var searchStr = "attachment; filename*=utf-8''";
                var index = contentDisposition.indexOf(searchStr);
                let fileName = decodeURI(contentDisposition.slice(index + searchStr.length));

                // BLOBファイルをダウンロード
                let link = document.createElement('a');
                if (window.navigator.msSaveBlob) {
                    // for IE
                    window.navigator.msSaveBlob(blob, fileName);
                } else {
                    // for Chrome
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                }

                // 一覧を再表示
                this.getBookmarkList();

            }).catch(err => {
                if (err.errorCode != null) {
                    this.props.router.navigate('/app/error', {
                        state: {code: err.errorCode, message: err.errorMessage}
                    });
                } else {
                    // 予期せぬ例外
                    this.props.router.navigate('/app/error');
                }
            }).finally(() => {
                // ローディング削除
                Loading.removeFromElement('execute_export_csv');
            });
        console.log('executeExport/end');
    }


    /**
     * おまとめ画面へ戻る.
     *
     */
    moveTopPage() {
        console.log('moveTopPage/start');
        let link = document.createElement('a');
        link.href = process.env.REACT_APP_OMATOME;
        link.click();
        console.log('moveTopPage/end');
    }

    /**
     * クリップボードにコピー
     *
     */
    copyFileId(fileId) {
        let clipboardText = fileId;
        const show_tooltip_list =this.state.show_tooltip_list
        if(navigator.clipboard == undefined) {
            window.clipboardData.setData('Text', clipboardText);
        } else {
            navigator.clipboard.writeText(clipboardText);
        }
        show_tooltip_list.push(fileId)
        this.setState({show_tooltip_list: show_tooltip_list})
        setTimeout(this.hideTooltip, 1000)
    }

    hideTooltip = () => {
        const show_tooltip_list =this.state.show_tooltip_list
        show_tooltip_list.shift()
        this.setState({show_tooltip_list: show_tooltip_list})
    }

    /**
     * 画面描画処理.
     *
     */
    render () {
        return (
            <div>
                <header className="header">
                    <div className="pagename icon-folder icon-white">よく使う書類入れ</div>
                    <span>
                        <button className="button" onClick={(e) => this.moveTopPage()}>トップメニュー 一覧</button>
                    </span>
                </header>

                <main className="main">
                    <div className="intro">
                        <p>よく使う書類入れへ入れたファイルがダウンロードできます。</p>
                    </div>

                    <div className="select_menu">
                        <div className="select_all">
                            <input type="checkbox" id="select_all" onChange={(e) => this.checkSelectedRow()} />
                            <label htmlFor="select_all">全て選択</label>
                        </div>
                        <div className="select_list">
                            <span>チェックした項目の</span>
                            <button className="button js-modal" data-tying="folder_operation" data-id="storagedownload" data-checklen="1">ダウンロード</button>
                            {(this.state.ai_user_flg) && (
                                <button id='execute_export_csv' className="button" data-tying="folder_operation" data-id="storageoutputcsv" data-checklen="1" onClick={(e) => this.outputFileInfoCsv()}>ファイルID出力</button>
                            )}
                            <button id="execute_delete" className="button button-delete" data-tying="folder_operation" data-checklen="1" onClick={(e) => this.deleteBookmark()}><span>削除</span></button>
                        </div>
                    </div>

                    <div className="table">
                        {this.renderTable()}
                        <div className="table_aside">
                            実際のファイルを削除すると、よく使う書類入れからも削除されます。
                        </div>
                    </div>
                </main>

                {/* ダウンロードウィンドウ */}
                <FileDownloadForm
                    reloadParentForm={this._didFinish.bind(this)}
                    selectedList={this.state.selected_row_list}
                />

            </div>
        )
    }


    /**
     * 画面描画処理（テーブル部分）.
     *
     */
    renderTable() {
        const isAiUser = this.state.ai_user_flg
        const show_tooltip_list = this.state.show_tooltip_list
        // 行を定義する
        const rows = this.state.bookmark_list.map((record, index) =>
            <tr key={'bookmark_' + index}>
                <td>
                    <input type="checkbox" id={"row" + index} data-tying="folder_operation" onChange={(e) => this.checkSelectedRow()} />
                    <label htmlFor={"row" + index}></label>
                </td>
                <td>
                    {record['folder_folder_id'] == null ? (
                        <a
                            className="icon-file copy-link-display"
                            href={'/app/fileviewer?fileId=' + record['file_file_id'] + '&is_paging=true&is_bookmark=true'}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {record['file_file_name']}
                        </a>
                    ) : (
                        <a className="icon-folder" href={'/app/filemanagement?folderid=' + record['folder_folder_id']}>
                            {record['folder_folder_name']}
                        </a>
                    )}
                    {/* コピーリンク出すか判定 */}
                    {(record['folder_folder_id'] == null && isAiUser) && (
                        <span className="copy-link-storage">
                            [<a className="copy-link" onClick={() => this.copyFileId(record['file_file_id'])}>
                                ファイルIDをコピー
                            </a>]
                            {(show_tooltip_list.includes(record['file_file_id'])) && (
                                <div className="tooltip_container">
                                    <p className="tooltip">コピーしました。</p>
                                </div>
                            )}
                        </span>
                    )}
                </td>
                <td>
                    {record['folder_folder_id'] == null ? (
                        <label>
                            {StringUtil.formatDate(new Date(record['file_update_date_time']), 'YYYY-MM-DD hh:mm')}
                            &nbsp;
                            {StringUtil.convertFileSize(record['file_file_size'])}
                        </label>
                    ) : (
                        <label>
                            {StringUtil.formatDate(new Date(record['folder_update_date_time']), 'YYYY-MM-DD hh:mm')}
                        </label>
                    )}
                </td>
            </tr>
        );

        // 表を返却
        return (
            <table data-alltarget>
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>名前</th>
                        <th>更新日時</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        )
    }


}

export default withRouter(BookmarkForm);
