import React from 'react';
import {withRouter} from '../../libs/withRouter';
import {ApiEnum, ApiClient, HttpMethod} from '../../api/Api';
import MessageUtil from '../../libs/MessageUtil';

/********************************************************************
 *
 * STORAGE-014 ファイル・フォルダ検索（フロント）
 *
 *******************************************************************/
class FrontWebSearchForm extends React.Component {

    /**
     * 初期化処理を行います.
     *
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            message: '',          // システムメッセージ
            search_word: '',      // 検索ワード
            select_radio: 'file', // 選択したラジオボタン
            placeholder: 'フォルダ名、ファイル名を入力', // 検索ワードに表示するplaceholder文字列
        }
    }


    /**
     * 検索ボタン押下時の処理.
     *
     */
    onSearchButton() {

        if (this.state.select_radio === 'file') {
            // ファイル・フォルダ検索
            this.searchFileFolder();
        } else if(this.state.select_radio === 'all') {
            // ファイル全文検索
            this.searchFullText();
        }
    }

    /**
     * ファイル名・フォルダ名で検索します.
     *
     */
    searchFileFolder() {


        let tmpBookmarkList =[];
        const search_indicator = document.getElementById('search_indicator');
        search_indicator.className = 'indicator_wrap_search display';


        ApiClient.storageApi(
            ApiEnum.Code.BOOKMARK_GET, 
            {}, 
            HttpMethod.Code.GET)
            .then(json => {
                tmpBookmarkList = json['bookmark_list'];

                const folderId = this.props.current_folder_id;
                const folderName = this.props.current_folder_name;
                const searchWord = this.state.search_word;

        
                ApiClient.storageApi(
                    ApiEnum.Code.FILE_SEARCH, 
                    {"folder_id": folderId, "search_word": searchWord}, 
                    HttpMethod.Code.GET)
                    .then(json => {
                        // ファイルの一覧＋お気に入り登録有無のリスト
                        let tmpFileListAddBookmark = [];

                        // 取得したファイル一覧
                        json['search_result_list'].map((record) => {
                            if (record['file_file_id'] != null) {
                                // ファイルの場合

                                // ブックマークの登録状況を取得
                                let filterResult = tmpBookmarkList.filter(function(bookmarkList) {
                                    if (bookmarkList['file_file_id'] === record['file_file_id']) {
                                        return true;
                                    }
                                    return false;
                                });

                                // ファイル一覧情報にブックマークの登録有無の情報を付与
                                let tmp = record;
                                tmp.is_file = true;
                                if (filterResult.length !== 0) {
                                    tmp.bookmark_flg = true;
                                    tmp.bookmark_id = filterResult[0]['bookmark_id'];
                                } else {
                                    tmp.bookmark_flg = false;
                                    tmp.bookmark_id = '';
                                }
                                // 明細行へ適用するスタイルを指定
                                tmp.detail_li_class = 'normal_list_img developer_list icon_file';
                                tmpFileListAddBookmark.push(tmp);

                            } else {
                                // フォルダの場合

                                // ブックマークの登録状況を取得
                                let filterResult = tmpBookmarkList.filter(function(bookmarkList) {
                                    if (bookmarkList['folder_folder_id'] === record['folder_folder_id']) {
                                        return true;
                                    }
                                    return false;
                                });

                                // ファイル一覧情報にブックマークの登録有無の情報を付与
                                let tmp = record;
                                tmp.is_file = false;
                                if (filterResult.length !== 0) {
                                    tmp.bookmark_flg = true;
                                    tmp.bookmark_id = filterResult[0]['bookmark_id'];
                                } else {
                                    tmp.bookmark_flg = false;
                                    tmp.bookmark_id = '';
                                }

                                // フォルダ名に応じて明細行へ適用するスタイルを指定
                                switch (record['folder_folder_name']) {
                                    case '防災マニュアル':
                                        tmp.detail_li_class = 'normal_folder_img developer_list_tree icon_bosai';
                                        break;
                                    case '管理のお知らせ':
                                        tmp.detail_li_class = 'normal_folder_img developer_list_tree icon_info';
                                        break;
                                    case '管理に関する届出':
                                        tmp.detail_li_class = 'normal_folder_img developer_list_tree icon_shinsei';
                                        break;
                                    case '取扱説明書':
                                        tmp.detail_li_class = 'normal_folder_img developer_list_tree icon_torisetu';
                                        break;
                                    default:
                                        tmp.detail_li_class = 'normal_folder_img developer_list_tree icon_folder';
                                        break;
                                }

                                // 説明文の有無に応じて明細行へ適用するスタイルを指定
                                if (record['folder_explanatory_text'] != null && record['folder_explanatory_text'].length !== 0) {
                                    tmp.detail_li_class += ' dir_angle_circle';
                                }
                                tmpFileListAddBookmark.push(tmp);

                            }

                            return [];
                        });

                        this.props.router.navigate('/app/frontfilesearchresult', {
                            state: {
                                current_folder_id: folderId,
                                current_folder_name: folderName,
                                search_result_list: tmpFileListAddBookmark,
                                search_word: searchWord,
                            },
                        })
                        // ファイル・フォルダ検索結果画面から再度呼ばれた場合は自画面を再描画
                        if (this.props.is_search_result) {
                            this.props.reloadParentForm(searchWord, tmpFileListAddBookmark);
                            search_indicator.className = 'indicator_wrap_search display_none';
                        }

                        // 自画面を閉じる
                        document.getElementById('search').classList.remove('is-show');

                        if (json['search_result_list'].length === 0) {
                            window.alert(MessageUtil.getMessage('I00001'));
                            search_indicator.className = 'indicator_wrap_search display_none';
                        }

                    }).catch(err => {
                        switch (err.errorCode) {
                            case 'E00007':
                                window.alert(err.errorMessage);
                                search_indicator.className = 'indicator_wrap_search display_none';
                                break;
                            default:
                                this.props.router.navigate('/app/error', {
                                    state: {code: err.errorCode, message: err.errorMessage}
                                });
                                break;
                        }
                    }
                )

            }).catch(err => {
                this.props.router.navigate('/app/error', {
                    state: {code: err.errorCode, message: err.errorMessage}
                });
                return;
            }).finally(() => {
                
            });
    }

    /**
     * ファイルに対し全文検索を行います.
     *
     */
    searchFullText() {

        let tmpBookmarkList =[];
        const search_indicator = document.getElementById('search_indicator');
        search_indicator.className = 'indicator_wrap_search display';
        
        ApiClient.storageApi(
            ApiEnum.Code.BOOKMARK_GET, 
            {}, 
            HttpMethod.Code.GET)
            .then(json => {
                tmpBookmarkList = json['bookmark_list'];

                const folderId = this.props.current_folder_id;
                const folderName = this.props.current_folder_name;
                const searchWord = this.state.search_word;
        
                ApiClient.storageApi(
                    ApiEnum.Code.FILE_FULL_TEXT_SEARCH, 
                    {"folder_id": folderId, "search_word": searchWord}, 
                    HttpMethod.Code.GET)
                    .then(json => {
                        // ファイルの一覧＋お気に入り登録有無のリスト
                        let tmpFileListAddBookmark = [];

                        // 取得したファイル一覧
                        json['search_result_list'].map((record) => {
                            if (record['file_file_id'] != null) {
                                // ファイルの場合

                                // ブックマークの登録状況を取得
                                let filterResult = tmpBookmarkList.filter(function(bookmarkList) {
                                    if (bookmarkList['file_file_id'] === record['file_file_id']) {
                                        return true;
                                    }
                                    return false;
                                });

                                // ファイル一覧情報にブックマークの登録有無の情報を付与
                                let tmp = record;
                                tmp.is_file = true;
                                if (filterResult.length !== 0) {
                                    tmp.bookmark_flg = true;
                                    tmp.bookmark_id = filterResult[0]['bookmark_id'];
                                } else {
                                    tmp.bookmark_flg = false;
                                    tmp.bookmark_id = '';
                                }
                                // 明細行へ適用するスタイルを指定
                                tmp.detail_li_class = 'normal_list_img developer_list icon_file';
                                tmpFileListAddBookmark.push(tmp);

                            } else {
                                // フォルダの場合

                                // ブックマークの登録状況を取得
                                let filterResult = tmpBookmarkList.filter(function(bookmarkList) {
                                    if (bookmarkList['folder_folder_id'] === record['folder_folder_id']) {
                                        return true;
                                    }
                                    return false;
                                });

                                // ファイル一覧情報にブックマークの登録有無の情報を付与
                                let tmp = record;
                                tmp.is_file = false;
                                if (filterResult.length !== 0) {
                                    tmp.bookmark_flg = true;
                                    tmp.bookmark_id = filterResult[0]['bookmark_id'];
                                } else {
                                    tmp.bookmark_flg = false;
                                    tmp.bookmark_id = '';
                                }

                                // フォルダ名に応じて明細行へ適用するスタイルを指定
                                switch (record['folder_folder_name']) {
                                    case '防災マニュアル':
                                        tmp.detail_li_class = 'normal_folder_img developer_list_tree icon_bosai';
                                        break;
                                    case '管理のお知らせ':
                                        tmp.detail_li_class = 'normal_folder_img developer_list_tree icon_info';
                                        break;
                                    case '管理に関する届出':
                                        tmp.detail_li_class = 'normal_folder_img developer_list_tree icon_shinsei';
                                        break;
                                    case '取扱説明書':
                                        tmp.detail_li_class = 'normal_folder_img developer_list_tree icon_torisetu';
                                        break;
                                    default:
                                        tmp.detail_li_class = 'normal_folder_img developer_list_tree icon_folder';
                                        break;
                                }

                                // 説明文の有無に応じて明細行へ適用するスタイルを指定
                                if (record['folder_explanatory_text'] != null && record['folder_explanatory_text'].length !== 0) {
                                    tmp.detail_li_class += ' dir_angle_circle';
                                }
                                tmpFileListAddBookmark.push(tmp);

                            }

                            return [];
                        });

                        this.props.router.navigate('/app/frontfilesearchresult', {
                            state: {
                                current_folder_id: folderId,
                                current_folder_name: folderName,
                                search_result_list: tmpFileListAddBookmark,
                                search_word: searchWord,
                            },
                        })
                        // ファイル・フォルダ検索結果画面から再度呼ばれた場合は自画面を再描画
                        if (this.props.is_search_result) {
                            this.props.reloadParentForm(searchWord, tmpFileListAddBookmark);
                            search_indicator.className = 'indicator_wrap_search display_none';
                        }

                        // 自画面を閉じる
                        document.getElementById('search').classList.remove('is-show');

                        if (json['search_result_list'].length === 0) {
                            window.alert(MessageUtil.getMessage('I00001'));
                            search_indicator.className = 'indicator_wrap_search display_none';
                        }

                    }).catch(err => {
                        switch (err.errorCode) {
                            case 'E00007':
                                window.alert(err.errorMessage);
                                search_indicator.className = 'indicator_wrap_search display_none';
                                break;
                            default:
                                this.props.router.navigate('/app/error', {
                                    state: {code: err.errorCode, message: err.errorMessage}
                                });
                                break;
                        }
                    }
                )

            }).catch(err => {
                this.props.router.navigate('/app/error', {
                    state: {code: err.errorCode, message: err.errorMessage}
                });
                return;
            }).finally(() => {
            });
    }

    /**
     * 検索キーワード入力.
     *
     * @param {*} e 
     */
    handleChange = (e) => {
        this.setState({ search_word: e.target.value });
    }

    /**
     * ラジオボタン選択
     *
     * @param {*} e 
     */
    onChangeRadio (e) {
        //選択状態保存
        this.setState({select_radio: e.target.id})
        if (e.target.id === 'file') {
            // ファイル・フォルダ検索
            this.setState({placeholder: 'フォルダ名、ファイル名を入力'})
        } else if(e.target.id === 'all') {
            // ファイル全文検索
            this.setState({placeholder: '検索する文字列を入力'})
        }
    }


    /**
     * 画面描画処理.
     *
     */
    render() {
        return (
            <div id="search" className="popup_wrap modal">
                <div className="popup_bg">
                    <div className="popup_body">
                        <p className="popup_title">ファイルとフォルダの検索ができます。</p>
                        <div className="popup_close button-close">✕</div>
                        <div className="select_button_wrap" onChange={this.onChangeRadio.bind(this)}>
                            <input id="file" type="radio" name="search" value="file" defaultChecked />
                            <label className="checkbox_style"></label>
                            <label htmlFor="file">ファイル・フォルダ名検索</label>
                        </div>
                        <div className="select_button_wrap" onChange={this.onChangeRadio.bind(this)}>
                            <input id="all" type="radio" name="search" value="all" />
                            <label className="checkbox_style"></label>
                            <label htmlFor="all">ファイル全文検索</label>
                        </div>
                        <form className="form_area">
                            <input type="text" className="search_textbox" placeholder={this.state.placeholder} onChange={this.handleChange} />
                            <input type="button" className="search_button" value="検索" onClick={(e) => this.onSearchButton()} />
                        </form>
                    </div>
                </div>

                <div id="search_indicator" className="indicator_wrap_search display_none">
                    <div className="indicator_bg">
                        <div className="indicator_icon_wrap">
                        <img src="../common/image/icon/loading.gif" alt="loading" />
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default withRouter(FrontWebSearchForm);

