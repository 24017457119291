import React from 'react';
import {withRouter} from '../../libs/withRouter';
import {ApiEnum, ApiClient, HttpMethod} from '../../api/Api';
import Loading from '../../libs/Loading';
import MessageUtil from '../../libs/MessageUtil';

/********************************************************************
 *
 * STORAGE-007 ファイル・フォルダ移動
 *
 *******************************************************************/
class FileMoveForm extends React.Component {

    /**
     * 初期化処理を行います.
     *
     * @param {*} props 
     */
    constructor (props) {
        super(props);
        this.state = {
            current_folder_id: '',       // 現在表示しているフォルダのID
            target_list: [],             // 移動対象一覧
            move_folder_list: [],        // 移動先フォルダ一覧
            selected_move_folder_id: '', // 指定した移動先フォルダID
        }
    }

    /**
     * コンポーネントがマウントされた場合に実行される処理です.
     *
     */
    componentDidMount() {
        this.props.onRef(this)
    }

    /**
     * コンポーネントがアンマウントされた場合に実行される処理です.
     *
     */
    componentWillUnmount() {
        this.props.onRef(null)
    }

    /**
     * State初期化.
     *
     * stateの初期化を行います.
     *
     */
    initMoveState(currentFolderId, selectedList) {
        console.log('initFileUpdateState/start');
        this.setState({
            current_folder_id: currentFolderId == null ? '' : currentFolderId,
            target_list: selectedList == null ? [] : selectedList,
        });

        if (currentFolderId != null && selectedList != null) {
            this.getFolderList(currentFolderId, selectedList);
        }
        console.log('initFileUpdateState/end');
    }

    /**
     * 移動先フォルダ選択.
     *
     */
    selectedMoveFolder (e) {
        console.log('selectedMoveFolder/start');
        this.setState({selected_move_folder_id: e.target.value});
        console.log('selectedMoveFolder/end');
    }

    /**
     * モーダル閉じる.
     *
     */
    closeModal() {
        // 自画面を閉じる
        document.getElementById('move').classList.remove('is-show');
        //閉じるときにリセット
        this.initMoveState();
    }

    /**
     * フォルダ一覧取得.
     *
     * 指定したフォルダ直下の一覧を取得します.
     *
     * @param {現在のフォルダID} argFolderId 
     * @param {選択したフォルダ・ファイル} argSelectedList 
     */
    getFolderList (argFolderId, argSelectedList) {
        console.log('getFolderList/start');

        let tmpUrlParam = '?folder_id=' + argFolderId + '&';
        argSelectedList.forEach(function(rec, index) {
            tmpUrlParam
                += 'targets[' + index + '].folder_id=' + rec.folder_folder_id + '&'
        })

        ApiClient.storageApi(
            ApiEnum.Code.FOLDER_GET_DEST_LIST + encodeURI(tmpUrlParam),
            {}, 
            HttpMethod.Code.GET)
            .then(json => {
                this.setState({
                    move_folder_list: json,
                });
            }).catch(err => {
                this.props.router.navigate('/app/error', {
                    state: {code: err.errorCode, message: err.errorMessage}
                });
            }
        )
        console.log('getFolderList/end');
    }

    /**
     * ファイル・フォルダ移動処理.
     *
     * ファイルおよびフォルダを指定フォルダへ移動します.
     *
     */
    executeMove () {
        console.log('executeMove/start');

        let tmpMoveTarget = [];
        this.state.target_list.forEach(function(rec) {
            if (rec['folder_folder_id'] == null) {
                // ファイルの場合
                tmpMoveTarget.push({
                    "id": rec['file_file_id'],
                    "file_flg": true,
                });
            } else {
                // フォルダの場合
                tmpMoveTarget.push({
                    "id": rec['folder_folder_id'],
                    "file_flg": false,
                });
            }
        })

        //バリデーション
        console.log(this.state.selected_move_folder_id)
        if (this.state.selected_move_folder_id == null || this.state.selected_move_folder_id === '') {
            //移動先未選択ならアラート
            window.alert(MessageUtil.getMessage('E03013'));
            return;
        }

        // ローディング追加
        Loading.addToElement('execute_move');

        ApiClient.storageApi(
            ApiEnum.Code.FILE_MOVE,
            {
                "targets": tmpMoveTarget,
                "folder_id": this.state.selected_move_folder_id,
                "parent_folder_id": this.state.current_folder_id,
            },
            HttpMethod.Code.PUT)
            .then(json => {
                // 自画面を閉じる
                this.closeModal();
                // 親画面を再描画
                this.props.reloadParentForm();
            }).catch(err => {
                switch (err.errorCode) {
                    case 'E00007':
                        window.alert(err.errorMessage);
                        break;
                    default:
                        this.props.router.navigate('/app/error', {
                            state: {code: err.errorCode, message: err.errorMessage}
                        });
                        break;
                }
            }).finally(() => {
                // ローディング削除
                Loading.removeFromElement('execute_move');
            });

        console.log('executeMove/end');
    }

    /**
     * 画面描画処理.
     *
     */
    render () {
        return (
            <>
                <div id="move" className="modal">
                    <div className="modal_content">
                        <p className="title">ファイル・フォルダ移動</p>
                        <div className="actionfield">
                            <div className="actionfield_confirm">
                                <p className="actionfield_head">これを移動</p>
                                <p className="actionfield_body">
                                    {this.state.target_list.map((record, index) => 
                                        <FileMoveRow key={'filemoverow_' + index} record={record}/>
                                    )}
                                </p>
                            </div>
                            <div className="actionfield_action">
                                <span className="actionfield_execution">移動先を選ぶ</span>
                                <div className="actionfield_body">
                                    <label className="select">
                                        <select name="move" onChange={(e) => this.selectedMoveFolder(e)}>
                                            <option value="">-- 移動先フォルダを選択してください --</option>
                                            { this.state.move_folder_list.map((record, index) =>
                                                <option key={'ilemove_select_' + index} value={record['folder_id']}>{record['folder_path']}</option>
                                            )}
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <div className="actionfield_unit">
                                <button id="execute_move" className="button drag" onClick={(e) => this.executeMove()}>移動</button>
                            </div>
                        </div>
                        <div className="button button-close" onClick={(e) => this.closeModal()}></div>
                    </div>
                </div>
            </>
        )
    }
}

/**
 * ファイル移動画面：選択したファイル
 * @param {プロパティ} props 
 */
const FileMoveRow = props => {
    const record = props.record;

    return record['folder_folder_id'] == null ? (
        <span className="actionfield_row icon-file">{record['file_file_name']}</span>
    ) : (
        <span className="actionfield_row icon-folder">{record['folder_folder_name']}</span>
    )
}

export default withRouter(FileMoveForm);
