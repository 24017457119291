import React, { useEffect, useState } from 'react';
import Loading from './FileViewerLoading';

function FileViewerVideo({ src }) {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const video = document.createElement('video');
        video.src = src;
        video.addEventListener('loadeddata', () => setLoading(false));
    }, [src]);

    return (
        <>
            {loading}
            <video className="swiper_image_size" 
                autoPlay 
                muted 
                playsInline
                webkit-playsInline
                controls
                preload="metadata"
                controlsList="nodownload"
                width="100%"
                height="auto"
                src={src}
            />
        </>
    );
}

export default FileViewerVideo;
